import { apiSlice } from "../app/api/apiSlice";

export const paymentGatewayApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGateways: builder.query({
            query: () => 'paymentgateways',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        updateGateway: builder.mutation({
            query: payload => ({
                url: `paymentgateways/update`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
    })
})

export const {
    useGetGatewaysQuery,
    useUpdateGatewayMutation,
} = paymentGatewayApiSlice