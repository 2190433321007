import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Row } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { useForm } from 'react-hook-form';


import { useGetSettingsQuery, useUpdateSettingsMutation } from '../../features/settingsApiSlice';

const SEOSettings = () => {

    const [updateSettings] = useUpdateSettingsMutation();

    const { data: fetchSettings, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetSettingsQuery({type:'seo', skip: false})
      
  
    useEffect(() => {
        refetch();
    }, [refetch]);
    
    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                
                if(fetchSettings.data) {
                    const s = fetchSettings.data;
                    console.log(s)
                    
                    setValue("txtMetaTitle", s.meta_title);
                    setValue("txtMetaKeywords", s.meta_keywords);
                    setValue("txtMetaDescription", s.meta_description);
                    
                }
                    
            }
        }   
        fn();

    }, [fetchSettings]);
    
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
      const onSubmit = async (data) => {
        if (data !== '') {
            
            const settings = {
                type: 'seo',
                meta_title: data.txtMetaTitle,
                meta_keywords: data.txtMetaKeywords,
                meta_description: data.txtMetaDescription,
            };
            
            await updateSettings(settings)
            .then(async res => {

                console.log(res.data)
                //setProductName(res.data.product)

                toast.success("Settings saved");
                
            });
            
        } else {
            toast.error("Settings not saved");
        }
      };

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Website Settings' parent="Website Settings" title="SEO Settings" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>SEO Settings</H5>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='txtMetaTitle'>Meta Title</Label>
                                <input className='form-control' id='txtMetaTitle' type='text' name='txtMetaTitle' {...register('txtMetaTitle')} />
                            </Col>
                            </Row>
                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='txtMetaKeywords'>Meta Keywords</Label>
                                <textarea className='form-control' id='txtMetaKeywords' rows={5}  name='txtMetaKeywords' {...register('txtMetaKeywords')}></textarea>
                            </Col>
                            
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='txtMetaDescription'>Meta Description</Label>
                                <textarea className='form-control' id='txtMetaDescription' rows={5} name='txtMetaDescription' {...register('txtMetaDescription')}></textarea>
                            </Col>
                            </Row>
                            
                            <Btn attrBtn={{ color: 'primary' }}>{'Save Settings'}</Btn>
                        </Form>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Fragment>
    );
};

export default SEOSettings;