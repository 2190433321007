import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Button,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router';
import { useForm, Controller } from 'react-hook-form';

import {
  useGetTestimonialListQuery,
  useAddTestimonialMutation,
} from '../../../features/addons/testimonialApiSlice';

const Testimonials = () => {
  const [content, setContent] = useState('');

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [activeChecked, setActiveChecked] = useState(true);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [editing, setEditing] = useState(false);

  const history = useNavigate();

  const [addTestimonial] = useAddTestimonialMutation();

  const {
    data: fetchTestimonials,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetTestimonialListQuery({ skip: false }); // Set skip to false to force a fetch every time

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        setData(fetchTestimonials.data);
      }
    };
    fn();
  }, [fetchTestimonials]);

  const {
    register,
    setValue,
    getValues,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      designation: '',
      company: '',
      date: '',
      activeCheck: 'checked',
    },
  });

  const onSubmit = async (values) => {
    if (values !== '') {
      if (validate(values)) {
        const formData = {
          name: values.name,
          designation: values.designation,
          company: values.company,
          tdate: getValues('date'),
          picture: '',
          active: activeChecked ? 1 : 0,
        };

        console.log(formData);

        try {
          const res = await addTestimonial(formData).unwrap();
          //console.log(res.data)
          let markers = [...data];
          markers.unshift(res.data);
          setData(markers);

          clears();

          toast.success('Data saved.');
        } catch (err) {
          if (err.status === 400) {
            toast.error(err.data?.message);
          } else {
            toast.error('Data not saved.');
          }
        }
      }
    } else {
      toast.error('Data not saved.');
    }
  };

  const validate = (values) => {
    return true;
  };

  const onAddContents = (id) => {
    //console.log(id);
    history(`/admin/addons/testimonials/add/${id}`);
  };

  const handleRowSelected = useCallback((state) => {
    console.log(state);
    setSelectedRow(state.selectedRows);
    if (state.selectedCount === 0) {
      clears();
    }
  }, []);

  const clears = () => {
    setEditing(false);

    reset();
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: (row) => `${row.id}`,
      omit: true,
    },
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: true,
      center: false,
    },
    {
      name: 'Designation',
      selector: (row) => `${row.designation}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Company',
      selector: (row) => `${row.company}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Active',
      selector: (row) => `${row.active === 1 ? 'Yes' : 'No'}`,
      sortable: true,
      center: true,
      style: { width: '80' },
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Button
          onClick={() => onAddContents(row.id)}
          className="btn btn-primary btn-sm px-1"
          style={{ whiteSpace: 'nowrap' }}
          type="button"
        >
          {'Add Remarks'}
        </Button>
      ),
      button: true, // This property ensures proper rendering
      //   conditionalCellStyles: [
      //     {
      //       when: 'max-width: 767px', // Apply styles for smaller screens
      //       style: {
      //         width: '100%', // Adjust column width for smaller screens
      //         whiteSpace: 'unset', // Prevent text wrapping
      //       },
      //     },
      //   ],
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Content Pages"
        parent="Addons"
        title="Testimonials"
        subParent=""
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Col md="6">
                  <H5>Testimonials</H5>
                </Col>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="name">
                        Name
                      </Label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Name"
                        name="name"
                        {...register('name', { required: true })}
                      />
                      <span className="d-block">
                        {errors.name && 'Name is required'}
                      </span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="designation">
                        Designation
                      </Label>
                      <input
                        className="form-control"
                        id="designation"
                        type="text"
                        placeholder="Designation"
                        name="designation"
                        {...register('designation', { required: true })}
                      />
                      <span className="d-block">
                        {errors.designation && 'Designation is required'}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="company">
                        Company
                      </Label>
                      <input
                        className="form-control"
                        id="company"
                        type="text"
                        placeholder="Company"
                        name="company"
                        {...register('company', { required: true })}
                      />
                      <span className="d-block">
                        {errors.company && 'Company is required'}
                      </span>
                    </Col>

                    <Col md="6 mb-3">
                      <Label className="form-label" for="date">
                        Date
                      </Label>
                      <input
                        className="form-control"
                        id="date"
                        type="date"
                        name="date"
                        {...register('date', { required: true })}
                      />
                      <span className="d-block">
                        {errors.date && 'Date is required'}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="description">
                        &nbsp;
                      </Label>
                      <div className="checkbox p-0">
                        <Input
                          className="form-check-input"
                          id="activeCheck"
                          type="checkbox"
                          checked={activeChecked}
                          onClick={() => {
                            setActiveChecked(!activeChecked);
                          }}
                          {...register('activeCheck')}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="activeCheck"
                        >
                          {'Active'}
                        </Label>
                      </div>
                    </Col>
                  </Row>

                  <Btn attrBtn={{ color: 'primary' }}>
                    {editing ? 'Update' : 'Save'}
                  </Btn>
                </Form>
              </CardBody>
              <CardBody>
                <DataTable
                  columns={tableColumns}
                  data={data}
                  striped={true}
                  center={true}
                  pagination
                  // selectableRows
                  // selectableRowsSingle
                  // selectableRowsHighlight
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleSelect}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Testimonials;
