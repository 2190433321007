import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    Form,
    Label,
    Container,
    Input,
    InputGroup,
    InputGroupText,
    Row,
    Button
} from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component';

import { useForm } from 'react-hook-form';

import { useGetListQuery, useCreateRecordMutation, useUpdateRecordMutation } from "../../../features/whyriiseApiSlice";
import {useTranslateTextMutation} from "../../../features/translateApiSlice";

const WhyRiise = () => {

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [toggleSelect, setToggleSelect] = useState(false);
    const [editing, setEditing] = useState(false)
    const [translateText] = useTranslateTextMutation();

    const [pictureUpload, setPictureUpload] = useState(null);
    const [pictureSrc, setPictureSrc] = useState();

    const [createRecord] = useCreateRecordMutation();
    const [updateRecord] = useUpdateRecordMutation();


    const { data: fetchRecords,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetListQuery({skip: false})


    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                //console.log(fetchUsers.data)
                setData(fetchRecords.data)
            }
        }
        fn();

    }, [fetchRecords]);

    const handleRowSelected = useCallback(state => {

        console.log(state)
        setSelectedRow(state.selectedRows);
        if(state.selectedCount === 0) {
            clears();
        }

    }, []);

    const clears = () => {
        reset()
        setEditing(false)

    }

    const handleEdit = () => {

        console.log(selectedRow[0])
        setEditing(true)
        setValue('title', selectedRow[0].title)
        setValue('title_de', selectedRow[0].title_de)
        setValue('description', selectedRow[0].description)
        setValue('description_de', selectedRow[0].description_de)
        setValue('category', selectedRow[0].category)

        if(selectedRow[0].picture && selectedRow[0].picture !== null && selectedRow[0].picture !== '')
            setPictureSrc(`${process.env.REACT_APP_BASE_URL}/${selectedRow[0].picture}`)
        else setPictureSrc(null)
    };


    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();


    const onSubmit = async (values) => {
        if (values !== '') {

            const formData = {
                title: values.title,
                title_de: values.title_de,
                description: values.description,
                description_de: values.description_de,
                category: values.category,
            }


            try {

                if(!editing) { // new
                    const res = await createRecord(formData).unwrap()
                    console.log(res.data)
                    let markers = [...data];
                    markers.push(res.data)
                    setData(markers)

                    clears();

                    toast.success("Record saved.");
                }
                else { // edit

                    formData.id = selectedRow[0].id


                    const res = await updateRecord(formData).unwrap()
                    console.log(res.data)
                    let markers = [...data];
                    let index = markers.findIndex(el => el.id === formData.id);

                    markers[index] = formData;

                    setData(markers)

                    clears();

                    toast.success("Record updated.");

                }
            }
            catch(err) {
                if(err.status === 400) {
                    toast.error(err.data?.message);
                }
                else {
                    toast.error("Record not saved.");
                }
            }


        } else {
            toast.error("Record not saved.");
        }
    };

    const onTranslate = async (e, type) => {
        e.preventDefault();

        let text = ''

        if(type === 'title')
            text = getValues('title')
        else
            text = getValues('description')

        console.log(text);

        if (text !== "") {
            try {
                if(type === 'title')
                    setValue('title_de', '')
                else setValue('description_de', '')

                const response = await translateText({ contents: text }).unwrap();

                if(type === 'title')
                    setValue('title_de', response.data)
                else setValue('description_de', response.data)

            } catch (err) {
                console.log(err);
            }
        }
    };


    const onPictureChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Read the selected file and set the image source
            const reader = new FileReader();
            reader.onload = (e) => {
                setPictureSrc(`${e.target.result}`);
            };
            reader.readAsDataURL(file);

            setPictureUpload(file);
        }

    };

    const onUploadPicture = (e) => {
        e.preventDefault();

        if (pictureUpload) {
            console.log(pictureUpload);
            console.log(selectedRow[0].id)
            const formData = new FormData();
            formData.append('id', selectedRow[0].id);
            formData.append('logo', pictureUpload, pictureUpload.name);
            formData.append('fileName', 'picture');
            formData.append('table', 'whyriise');
            formData.append('column', 'picture');

            callUploadFile(formData);

            return true;
        }
    };

    const callUploadFile = (formData) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/upload`, {
            method: 'PUT',
            body: formData,
        })
            .then((res) => {
                if (!res.ok) {
                    return Promise.reject(res);
                }
                return res.json();
            })
            .then((data) => {
                console.log(data);
                toast.success(data.message);
            })
            .catch((err) => {
                console.error(err);
                toast.error(err);
            });
    };


    const tableColumns = [
        {
            name: 'Id',
            selector: row => `${row.id}`,
            omit: true
        },
        {
            name: 'Title',
            selector: row => row['title'],
            sortable: true,
            center: false,
        },
        {
            name: 'Description',
            selector: row => `${row.description}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Category',
            selector: row => `${row.category}`,
            sortable: true,
            center: true,
        },
    ];

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Product Suggestion' parent="Addons" title="Why Riise" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <H5>Why Riise</H5>
                            </CardHeader>
                            <CardBody>
                                <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col md='6'>
                                            <div className="mb-3">
                                                <Label className='form-label' for='title'>Title</Label>
                                                <input className='form-control' id='title' type='text' name='title' placeholder='Title' {...register('title', { required: true })} />
                                                <span className='d-block txt-danger'>{errors.title && 'Title is required'}</span>
                                            </div>

                                        </Col>
                                        <Col md='6 mb-3'>
                                            <div className="mb-3">

                                                <div>
                                                    <Label className='form-label' for='title_de'>Title (German)</Label>&nbsp;
                                                    <Button type="button" className="btn py-0 px-2" onClick={(e) => onTranslate(e, 'title')}> <i className="fa fa-language"></i></Button>
                                                </div>

                                                <input className='form-control' id='title_de' type='text' name='title_de' placeholder='Title' {...register('title_de', { required: true })} />
                                                <span className='d-block txt-danger'>{errors.title_de && 'Title is required'}</span>
                                            </div>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md='6'>

                                            <div className="mb-3">
                                                <Label for='description'>Description</Label>
                                                <input className='form-control' id='description' name='description' type='text' placeholder='Description' {...register('description', { required: true })} />
                                                <span className='d-block txt-danger w-100'>{errors.description && 'Description is required'}</span>
                                            </div>

                                        </Col>

                                        <Col md='6'>

                                            <div className="mb-3">
                                                <div>
                                                    <Label for='description_de'>Description (German)</Label>&nbsp;
                                                    <Button type="button" className="btn py-0 px-2" onClick={(e) => onTranslate(e, 'description')}> <i className="fa fa-language"></i></Button>
                                                </div>
                                                <input className='form-control' id='description_de' name='description_de' type='text' placeholder='Description' {...register('description_de', { required: true })} />
                                                <span className='d-block txt-danger w-100'>{errors.description_de && 'Description is required'}</span>
                                            </div>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md='6'>

                                            <div className="mb-3">
                                                <Label for='category'>Category</Label>
                                                <select className='form-control' id='category' name='category' {...register('category', { required: true })}>
                                                    <option value='' disabled selected>---Select Category---</option>
                                                    <option value='whyriise'>Why Riise</option>
                                                    <option value='faq'>FAQ</option>
                                                </select>
                                                <span className='d-block txt-danger w-100'>{errors.category && 'Category is required'}</span>
                                            </div>
                                        </Col>

                                        <Col md='6'>
                                            <Label className="form-label" for="picture">Picture</Label>
                                            {pictureSrc ? (
                                                <div>
                                                    <img src={pictureSrc} alt="Selected" width="70" />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{ pictureSrc }}
                                                ></div>
                                            )}

                                            <input
                                                className="form-control" type="file"
                                                name="picture" accept="image/*"
                                                onChange={(e) => onPictureChange(e)}
                                            />
                                            <Button disabled={!editing}
                                                style={{ color: 'primary' }}
                                                onClick={(e) => onUploadPicture(e)}
                                            >
                                                {'Upload'}
                                            </Button>

                                        </Col>
                                    </Row>

                                    <Btn attrBtn={{ color: 'primary'}}>{editing?'Update':'Save'}</Btn>
                                </Form>
                            </CardBody>

                            <CardBody>
                                <H5>Records</H5>
                                <>
                                    {(selectedRow.length !== 0) && <>
                                        <div className={'float-end w-100 d-flex bg-light-info'}>
                                            <div className={`d-flex align-items-center justify-content-between p-2`}>

                                                <Btn attrBtn={{ color: 'primary', onClick: () => handleEdit() }}>Edit</Btn>
                                            </div>
                                        </div>
                                    </>
                                    }
                                    <DataTable
                                        columns={tableColumns}
                                        data={data}
                                        striped={true}
                                        center={true}
                                        pagination
                                        selectableRows
                                        selectableRowsSingle
                                        selectableRowsHighlight
                                        onSelectedRowsChange={handleRowSelected}
                                        clearSelectedRows={toggleSelect}
                                    />
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>


            <ToastContainer/>
        </Fragment>
    );
};

export default WhyRiise;