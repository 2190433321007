import { apiSlice } from "../../app/api/apiSlice";

export const ingredientApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIngredients: builder.query({
            query: () => 'ingredient/all',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        addIngredient: builder.mutation({
            query: payload => ({
                url: 'ingredient',
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateIngredient: builder.mutation({
            query: payload => ({
                url: `ingredient`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
        addStock: builder.mutation({
            query: payload => ({
                url: 'quantity/add',
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateStock: builder.mutation({
            query: payload => ({
                url: `quantity/update`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
        getStock: builder.query({
            query: () => 'quantity',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        getUnits: builder.query({
            query: () => 'unit/all',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
    })
})

export const {
    useGetUnitsQuery,
    useGetIngredientsQuery,
    useAddIngredientMutation,
    useUpdateIngredientMutation,
    useGetStockQuery,
    useAddStockMutation,
    useUpdateStockMutation
} = ingredientApiSlice