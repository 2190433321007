import React, {Fragment} from 'react'
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';

import RecentSales from './Default/RecentSales';
import TotalUserAndFollower from "./Default/TotalUserAndFollower"

import PreAccountCard from './Default/PreAccountCard';

import WidgetsWrapper from './Default/WidgetsWraper';

const NewDashboard = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Default" parent="Dashboard" title="Default" />
      <Container fluid={true}>
        <Row className="widget-grid">
          {/* <RecentSales />
          <TotalUserAndFollower />
          <PreAccountCard /> */}
          
        </Row>
      </Container>
    </Fragment>
  );
}

export default NewDashboard;