import { apiSlice } from "../app/api/apiSlice";

export const whyriiseApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getList: builder.query({
            query: () => ({url: `whyriise`}),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        createRecord: builder.mutation({
            query: payload => ({
                url: `whyriise`,
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateRecord: builder.mutation({
            query: payload => ({
                url: `whyriise`,
                method: 'PUT',
                body: { ...payload }
            }),
        }),
    })
})

export const {
    useGetListQuery,
    useCreateRecordMutation,
    useUpdateRecordMutation,
} = whyriiseApiSlice