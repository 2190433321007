import React, {Fragment, useState, useEffect} from "react";
import { Row, Col, Container, Card, CardHeader, CardBody  } from "reactstrap";
import { Breadcrumbs, H5, H4 } from '../../AbstractElements';
import { useGetInquiriesQuery, useGetInquiryQuery } from "../../features/users/userApiSlice";

const Inquiries = () => {
    
    const [data, setData] = useState([])
    const [select, setSelect] = useState(0)

    const [id, setId] = useState(0)
    const [inquiry, setInquiry] = useState({first_name:'', last_name: '', phone: '', email: '', created_at: '', message: ''})
    
    const { data: fetchInquiries, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetInquiriesQuery({skip: false})
      
    
    useEffect(() => {
        refetch();
    }, [refetch]);
    
    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                console.log(fetchInquiries.data)
                setData(fetchInquiries.data)
            }
        }   
        fn();

    }, [fetchInquiries]);
    
    
    const { data: fetchInquiry, 
        isSuccess: isInquirySuccess,
        refetch: inquiryRefetch } = useGetInquiryQuery({id, id, skip: false})
    
    
    useEffect(() => {
        inquiryRefetch();
    }, [id]);
    
    useEffect(() => {

        const fn = () => {
            if(isInquirySuccess) {
                console.log(fetchInquiry.data)
                setInquiry(fetchInquiry.data)
            }
        }   
        fn();

    }, [fetchInquiry]);
    
        
    const onInquiryClick = (e, index) => {

        setSelect(index)

        setId( data[index].id )
    }

    return (
    <Fragment>
        <Breadcrumbs mainTitle='User Management' parent="Web" title="Inquiries" subParent='' />
        <Container fluid={true}>
            <Row>
                <Col sm="12">
                <Card>
                    <CardHeader>
                    <H5>Inquiries</H5>
                    </CardHeader>
                    <CardBody>
                        <Row style={{minHeight: 500}}>
                            <Col md='3'>
                                <Card style={{height: '100%'}}>
                                <CardBody>
                                <H5>Messages</H5>
                                <ul className="inquiry">
                                    {data.map((ele, index) => (
                                        <li key={index} className={ select === index ? ' selected' : ''} onClick={(e) => onInquiryClick(e, index)}>
                                            <div>{ele.first_name} {ele.last_name}</div>
                                            <span>{ele.date}</span>
                                        </li>
                                    ))}
                                </ul>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col md='9'>
                                <Card style={{height: '100%'}}>
                                <CardBody className="inquiry">
                                <H5>Message Detail</H5>
                                <Row>
                                    <Col md='7'><div className="control">{inquiry.first_name} {inquiry.last_name}</div></Col>
                                    <Col md='5' className="text-end"><div className="control">{inquiry.date}</div></Col>
                                </Row>
                                <Row>
                                    <Col md='7'><div className="control">{inquiry.email}</div></Col>
                                    <Col md='5'><div className="control">{inquiry.phone}</div></Col>
                                </Row>
                                <Row>
                                    <Col md='12'>
                                    <div className="message">{inquiry.message}</div>
                                    </Col>
                                    
                                </Row>
                                </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </Fragment>
    )
}

export default Inquiries;