import { apiSlice } from "../../app/api/apiSlice";

export const productApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({
            query: () => 'product/all',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        addProduct: builder.mutation({
            query: payload => ({
                url: 'product',
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateProduct: builder.mutation({
            query: payload => ({
                url: `product`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
    })
})

export const {
    useGetProductsQuery,
    useAddProductMutation,
    useUpdateProductMutation
} = productApiSlice