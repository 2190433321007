import { apiSlice } from "../../app/api/apiSlice";

export const TestimonialApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTestimonialList: builder.query({
            query: () => 'testimonial/list',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        getTestimonial: builder.query({
            query: (payload) => ({
                url: 'testimonialbyid',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        addTestimonial: builder.mutation({
            query: payload => ({
                url: 'testimonial',
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateTestimonial: builder.mutation({
            query: payload => ({
                url: `testimonial`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
        })
    })

export const {
    useGetTestimonialListQuery,
    useGetTestimonialQuery,
    useAddTestimonialMutation,
    useUpdateTestimonialMutation,
} = TestimonialApiSlice