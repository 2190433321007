import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Input, Row, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from "react-toastify";

import { useNavigate } from 'react-router';
import { useForm, Controller } from 'react-hook-form';

import { useTranslateTextMutation } from '../../../features/translateApiSlice';

import { useGetFaqsListQuery, useGetFaqsQuery, useAddFaqsMutation, useUpdateFaqsMutation } from '../../../features/addons/faqsApiSlice';


const Faqs = () => {
    
    const [content, setContent] = useState('');

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [activeChecked, setActiveChecked] = useState(true)
    const [toggleSelect, setToggleSelect] = useState(false);
    const [editing, setEditing] = useState(false)

    const history = useNavigate();

    const [addFaqs] = useAddFaqsMutation();
    const [updateFaqs] = useUpdateFaqsMutation();

    const [translateText] = useTranslateTextMutation();

    const { data: fetchFaqs, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetFaqsListQuery({skip: false});


    const [faqSkip, setFaqSkip] = useState(true);

    const { data: fetchFaq, 
        isSuccess: isFaqSuccess,
        refetch: faqRefetch } = useGetFaqsListQuery({skip: faqSkip});
    
    useEffect(() => {
        refetch();
    }, [refetch]);
    
    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                setData(fetchFaqs.data)
            }
        }   
        fn();

    }, [fetchFaqs]);


    const {
        register,
        setValue,
        getValues,
        setError,
        handleSubmit,
        formState: { errors },
      } = useForm({
        defaultValues: {
          title: '',
          title_de: '',
          contents: '',
          contents_de: '',
          activeCheck: 'checked'
        }
      });
    
      const onSubmit = async (values) => {
        if (values !== '') {
            
            if(validate(values)) {

                const formData = {
                    title: values.title,
                    title_de: values.title_de,
                    contents: values.contents,
                    contents_de: values.contents_de,
                    active: activeChecked?1:0
                }
    
                console.log(formData)
                
                try {
                    if(!editing) {
                        const res = await addFaqs(formData).unwrap()
                        //console.log(res.data)
                        let markers = [...data];
                        markers.unshift(res.data)
                        setData(markers)
            
                        clears();
    
                        toast.success("Data saved.");
                    }
                    else {

                        formData.id = selectedRow[0].id
                        
                        const res = await updateFaqs(formData).unwrap()
                        console.log(res.data)
                        let markers = [...data];
                        let index = markers.findIndex(el => el.id === formData.id);

                        markers[index] = res.data;
                        
                        setData(markers)
            
                        clears();

                        toast.success("Data updated.");
                    }
                    
                }
                catch(err) {
                    if(err.status === 400) {
                        toast.error(err.data?.message);
                    }
                    else {
                        toast.error("Data not saved.");
                    }
                }
            }

        } else {
            toast.error("Data not saved.");
        }
      };

      const validate = (values) => {

        return true;
      }
    
    
    const handleRowSelected = useCallback(state => {
        
        setSelectedRow(state.selectedRows);
        if(state.selectedCount === 0) {
            clears();
        }
        
    }, []);

    const clears = () => {
        setEditing(false)
        setValue('title', '')
        setValue('title_de', '')
        
        setValue('contents', '')
        setValue('contents_de', '')
        setActiveChecked(true)
    }

    
    const handleEdit = () => {

        console.log(selectedRow[0])
        setEditing(true)
        setValue('title', selectedRow[0].title)
        setValue('title_de', selectedRow[0].title_de)
        setValue('contents', selectedRow[0].contents)
        setValue('contents_de', selectedRow[0].contents_de)

        setActiveChecked(selectedRow[0].active === 'Yes')
    };

    const onTranslate = async (e, element) => {
        e.preventDefault();

        let text;

        if(element === 'title')
            text = getValues('title');
        else text = getValues('contents');
        
        console.log(text);

        if(text !== '') {
            try {
                if(element === 'title')
                    setValue('title_de', '')
                else setValue('contents_de', '')

                const response = await translateText({contents: text}).unwrap();
                console.log(response.data)

                if(element === 'title')
                    setValue('title_de', response.data)
                else setValue('contents_de', response.data);

            }
            catch(err) {
                console.log(err);
                //toast.error(err);
            }
            
        }
    }

    const tableColumns = [
        {
            name: 'Id',
            selector: row => `${row.id}`,
            omit: true
        },
        {
            name: 'Question',
            selector: row => row['title'],
            sortable: true,
            center: false,
        },
        {
            name: 'Answer',
            selector: row => row['contents'],
            sortable: true,
            center: false,
        },
        {
            name: 'Question (German)',
            selector: row => row['title_de'],
            omit: true
        },
        {
            name: 'Answer (German)',
            selector: row => row['contents_de'],
            omit: true
        },
        {
            name: 'Active',
            selector: row => `${row.active}`,
            sortable: true,
            center: true,
            style:{width:'80'}
        },
        
    ];


    return (
        <Fragment>
            <Breadcrumbs mainTitle='FAQs' parent="Addons" title="FAQs" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                            <Col md='6'><H5>FAQs</H5></Col>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                            <Col md='6 mb-3'>
                                <Label className='form-label' for='title'>Question (English)</Label>
                                <input className='form-control' id='title' type='text' placeholder='Question' name='title' {...register('title', { required: true })} />
                                <span className='d-block'>{errors.title && 'Question is required'}</span>
                                
                            </Col>
                            <Col md='6 mb-3'>
                                
                                <div>
                                    <Label className='form-label' for='title_de'>Question (German)</Label>&nbsp;
                                    <Button type='button' className='btn py-0 px-2' onClick={e => onTranslate(e, 'title')}><i className="fa fa-language"></i></Button>
                                </div>
                                <input className='form-control' id='title_de' type='text' placeholder='Question' name='title_de' {...register('title_de')} />
                                
                            </Col>
                            </Row>
                            <Row>
                            <Col md='6 mb-3'>
                                <Label className='form-label' for='contents'>Answer (English)</Label>
                                <textarea className='form-control' rows={5} id='contents'  name='contents' {...register('contents', { required: true })}></textarea>
                                <span className='d-block'>{errors.title && 'Answer is required'}</span>
                                
                            </Col>
                            <Col md='6 mb-3'>
                                
                                <div>
                                    <Label className='form-label' for='contents_de'>Answer (German)</Label>&nbsp;
                                    <Button type='button' className='btn py-0 px-2' onClick={e => onTranslate(e, 'contents')}><i className="fa fa-language"></i></Button>
                                </div>
                                <textarea className='form-control' id='contents_de' rows={5} name='contents_de' {...register('contents_de')}></textarea>
                                
                            </Col>
                            </Row>
                            <Row>
                            
                            <Col md='6 mb-3'>
                            <Label className='form-label' for='activeCheck'>&nbsp;</Label>
                            <div className='checkbox p-0'>
                                
                                <Input className='form-check-input' id='activeCheck' type='checkbox' checked={activeChecked} onClick={() => {setActiveChecked(!activeChecked)}} {...register('activeCheck')}/>
                                <Label className='form-check-label' htmlFor='activeCheck'>
                                    {'Active'}
                                </Label>
                                </div>
                            </Col>
                            </Row>
                            
                            <Btn attrBtn={{ color: 'primary' }}>{(editing ? 'Update' : 'Save')}</Btn>
                        </Form>
                        </CardBody>
                        <CardBody>
                            {(selectedRow.length !== 0) && <>
                                <div className={'float-end w-100 d-flex bg-light-info'}>
                                    <div className={`d-flex align-items-center justify-content-between p-2`}>
                                        
                                        <Btn attrBtn={{ color: 'primary', onClick: () => handleEdit() }}>Update</Btn>
                                    </div>
                                </div>
                                    </>
                            }
                            <DataTable
                                columns={tableColumns}
                                data={data}
                                striped={true}
                                center={true}
                                pagination
                                selectableRows
                                selectableRowsSingle
                                selectableRowsHighlight
                                onSelectedRowsChange={handleRowSelected}
                                clearSelectedRows={toggleSelect}
                            />
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Faqs;