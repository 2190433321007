import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from "react-toastify";

import { useForm, Controller } from 'react-hook-form';
import { IngredientsTitle } from '../../../Constant';

import { useGetIngredientsQuery, useGetStockQuery, useAddStockMutation, useUpdateStockMutation } from '../../../features/addons/ingredientsApiSlice';

const AddStock = () => {
    
    const [data, setData] = useState([]);
    const [stockData, setStockData] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [activeChecked, setActiveChecked] = useState(false)
    const [toggleSelect, setToggleSelect] = useState(false);
    const [editing, setEditing] = useState(false)


    const [addStock] = useAddStockMutation();
    const [updateStock] = useUpdateStockMutation();

    const { data: fetchIngredients, 
        isLoading,
        isSuccess,
        isError,
        error } = useGetIngredientsQuery({skip: false}); // Set skip to false to force a fetch every time
    
    const { data: fetchStock, 
        isSuccess: isStockSuccess,
        refetch } = useGetStockQuery({skip: false}); // Set skip to false to force a fetch every time
    

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                setData(fetchIngredients.data)
            }
        }   
        fn();

    }, [fetchIngredients]);


    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                
                if(fetchStock && fetchStock.data)
                    setStockData(fetchStock.data)
            }
        }   
        fn();

    }, [fetchStock]);


    const {
        register,
        setValue,
        getValues,
        control, 
        setError,
        handleSubmit,
        formState: { errors },
      } = useForm({
        defaultValues: {
          name: '',
          //quantity: '0',
          description: '',
          activeCheck: 'checked'
        }
      });
    
      const onSubmit = async (values) => {
        if (values !== '') {
            
            if(validate(values)) {

                const formData = {
                    user_id: 1,
                    ingredient_id: getValues("optIngredient"),
                    quantity: values.quantity,
                }
    
                console.log(formData)
                
                try {

                    if(!editing) { // new user
                        const res = await addStock(formData).unwrap()
                        //console.log(res.data)
                        let markers = [...stockData];
                        markers.push(res.data)
                        setStockData(markers)
            
                        clears();

                        toast.success("Quantity saved.");
                    }
                    else { // edit user
                        
                        formData.id = selectedRow[0].id
                        

                        const res = await updateStock(formData).unwrap()
                        console.log(res.data)
                        let markers = [...stockData];
                        let index = markers.findIndex(el => el.id === formData.id);

                        markers[index] = res.data;
                        
                        setStockData(markers)
            
                        clears();

                        toast.success("Quantity updated.");

                    }
                }
                catch(err) {
                    if(err.status === 400) {
                        toast.error(err.data?.message);
                    }
                    else {
                        toast.error("Quantity not saved.");
                    }
                }
            }

        } else {
            toast.error("Quantity not saved.");
        }
      };

      const validate = (values) => {

        let quantity = values.quantity;
        if((isNaN(+quantity))) {
            setError('quantity', {
                type: 'manual',
                message: 'Please enter valid quantity.',
            });
            return false;
        }

        return true;
      }
    
    
    const handleRowSelected = useCallback(state => {
        
        console.log(state)
        setSelectedRow(state.selectedRows);
        if(state.selectedCount === 0) {
            clears();
        }
        
    }, []);

    const clears = () => {
        setEditing(false)
        setValue('quantity', '')
    }
    
    const handleEdit = () => {

        console.log(selectedRow[0])
        setEditing(true)
        setValue('optIngredient', selectedRow[0].ingredient_id)
        setValue('quantity', selectedRow[0].quantity)
    };

    const tableColumns = [
        {
            name: 'Id',
            selector: row => `${row.id}`,
            omit: true
        },
        {
            name: 'IngredientId',
            selector: row => `${row.ingredient_id}`,
            omit: true
        },
        {
            name: 'Ingredient',
            selector: row => `${row.ingredient} (${row.unit})`, //row['ingredient'],
            sortable: true,
            center: false,
        },
        {
            name: 'Quantity',
            selector: row => `${row.quantity}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Date',
            selector: row => `${row.datetime}`,
            sortable: true,
            center: true,
        },
        
    ];


    return (
        <Fragment>
            <Breadcrumbs mainTitle='Ingredients List' parent="Addons" subParent='Ingredients' title="Stock" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>Add Stock</H5>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                            
                            </Row>
                            <Row>
                            <Col md='6 mb-3'>
                                <Label className='form-label' for='optIngredient'>Ingredients</Label>

                                <Controller
                                    name="optIngredient"
                                    control={control}
                                    rules={{ required: true }} // Add a validation rule for required selection
                                    render={({ field }) => (
                                        <select {...field}
                                        className="form-select form-control" 
                                        >
                                        <option value="">{"--Select Ingredient--"}</option>
                                        {data.map((ing, index) => (
                                            <option key={index} value={ing.id}>
                                                {`${ing.ingredient} (${ing.unit})`}
                                            </option>
                                        ))}
                                        </select>
                                    )}
                                />
                                <span className='d-block txt-danger'>{errors.optIngredient && (errors.optIngredient.message ? errors.optIngredient.message : 'Please select ingredient')}</span>
                                
                            </Col>
                            <Col md='6 mb-3'>
                                <Label className='form-label' for='quantity'>Quantity</Label>
                                <input className='form-control' id='quantity' type='text' placeholder='Quantity' name='quantity' {...register('quantity', { required: true })} />
                                <span className='d-block txt-danger'>{errors.quantity && (errors.quantity.message ? errors.quantity.message : 'Quantity is required')}</span>
                                
                            </Col>
                            
                            </Row>
                            
                            
                            <Btn attrBtn={{ color: 'primary' }}>{(editing ? 'Update Stock' : 'Save Stock')}</Btn>
                        </Form>
                        </CardBody>
                        <CardBody>
                            {(selectedRow.length !== 0) && <>
                                <div className={'float-end w-100 d-flex bg-light-info'}>
                                    <div className={`d-flex align-items-center justify-content-between p-2`}>
                                        
                                        <Btn attrBtn={{ color: 'primary', onClick: () => handleEdit() }}>Update</Btn>
                                    </div>
                                </div>
                                    </>
                            }
                            <DataTable
                                columns={tableColumns}
                                data={stockData}
                                striped={true}
                                center={true}
                                pagination
                                selectableRows
                                selectableRowsSingle
                                selectableRowsHighlight
                                onSelectedRowsChange={handleRowSelected}
                                clearSelectedRows={toggleSelect}
                            />
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default AddStock;