import { apiSlice } from "../../app/api/apiSlice";

export const subscriptionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSubscriptionList: builder.query({
            query: () => 'subscriptions/list',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        updateSubscription: builder.mutation({
            query: payload => ({
                url: `subscriptions`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
    })
})

export const {
    useGetSubscriptionListQuery,
    useUpdateSubscriptionMutation,
} = subscriptionApiSlice