import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Button,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';

import { useForm, Controller } from 'react-hook-form';
import { IngredientsTitle } from '../../../Constant';

import {
  useGetIngredientsQuery,
  useAddIngredientMutation,
  useUpdateIngredientMutation,
  useGetUnitsQuery,
} from '../../../features/addons/ingredientsApiSlice';
import { useTranslateTextMutation } from '../../../features/translateApiSlice';

const Ingredients = () => {
  const [pictureUpload, setPictureUpload] = useState(null);
  const [pictureSrc, setPictureSrc] = useState();

  const [data, setData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [activeChecked, setActiveChecked] = useState(false);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [editing, setEditing] = useState(false);

  const [addIngredient] = useAddIngredientMutation();
  const [updateIngredient] = useUpdateIngredientMutation();
  const [translateText] = useTranslateTextMutation();

  const {
    data: fetchIngredients,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetIngredientsQuery({ skip: false }); // Set skip to false to force a fetch every time

  const {
    data: fetchUnits,
    isSuccess: isUnitSuccess,
    refetch: unitRefetch,
  } = useGetUnitsQuery({ skip: false });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        console.log(fetchIngredients.data);
        setData(fetchIngredients.data);
      }
    };
    fn();
  }, [fetchIngredients]);

  useEffect(() => {
    const fn = () => {
      if (isUnitSuccess) {
        setUnitData(fetchUnits.data);
      }
    };
    fn();
  }, [fetchUnits]);

  const onTranslate = async (e, type) => {
    e.preventDefault();

    let contents = '';

    if (type === 'name') contents = getValues('name').trim();
    else contents = getValues('description').trim();

    console.log(contents);

    if (contents !== '') {
      try {
        if (type === 'name') setValue('name_de', '');
        else setValue('description_de', '');

        const response = await translateText({ contents }).unwrap();
        console.log(response.data);

        if (type === 'name') setValue('name_de', response.data);
        else setValue('description_de', response.data);
      } catch (err) {
        console.log(err);
        //toast.error(err);
      }
    }
  };

  const {
    register,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      //quantity: '0',
      description: '',
      description_de: '',
      activeCheck: 'checked',
    },
  });

  const onSubmit = async (values) => {
    if (values !== '') {
      if (validate(values)) {
        const formData = {
          ingredient: values.name,
          ingredient_de: values.name_de,
          unit_id: getValues('optUnit'),
          description: values.description,
          description_de: values.description_de,
          active: activeChecked ? 1 : 0,
        };

        console.log(formData);

        try {
          if (!editing) {
            // new
            const res = await addIngredient(formData).unwrap();
            //console.log(res.data)
            let markers = [...data];
            markers.unshift(res.data);
            setData(markers);

            clears();

            toast.success('Ingredient saved.');
          } else {
            // edit

            formData.id = selectedRow[0].id;

            const res = await updateIngredient(formData).unwrap();
            //console.log(res.data)
            let markers = [...data];
            let index = markers.findIndex((el) => el.id === formData.id);

            markers[index] = res.data;

            setData(markers);

            clears();

            toast.success('Ingredient updated.');
          }
        } catch (err) {
          if (err.status === 400) {
            toast.error(err.data?.message);
          } else {
            toast.error('Ingredient not saved.');
          }
        }
      }
    } else {
      toast.error('Ingredient not saved.');
    }
  };

  const validate = (values) => {
    const index = data.findIndex(
      (el) =>
        el.ingredient === values.name && editing && el.id !== selectedRow[0].id
    );
    if (index > -1) {
      setError('name', {
        type: 'manual',
        message: 'Ingredient already taken.',
      });
      return false;
    }

    return true;
  };

  const handleRowSelected = useCallback((state) => {
    console.log(state);
    setSelectedRow(state.selectedRows);
    if (state.selectedCount === 0) {
      clears();
    }
  }, []);

  const clears = () => {
    setEditing(false);
    setValue('name', '');
    setValue('name_de', '');
    setValue('description', '');
    setValue('description_de', '');
    setValue('optUnit', '');
    setActiveChecked(true);
  };

  const handleEdit = () => {
    console.log(selectedRow[0]);
    setEditing(true);
    setValue('name', selectedRow[0].ingredient);
    setValue('name_de', selectedRow[0].ingredient_de);
    setValue('description', selectedRow[0].description);
    setValue('description_de', selectedRow[0].description_de);

    setValue('optUnit', selectedRow[0].unit_id);

    setActiveChecked(selectedRow[0].active === 'Yes');

    if (selectedRow[0].picture !== '') {
      const src_pic = `${process.env.REACT_APP_BASE_URL}/${selectedRow[0].picture}`;

      setPictureSrc(src_pic);
    } else {
      setPictureSrc('');
    }
  };

  const onPictureChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Read the selected file and set the image source
      const reader = new FileReader();
      reader.onload = (e) => {
        setPictureSrc(e.target.result);
      };
      reader.readAsDataURL(file);

      setPictureUpload(file);
      //setSelectedImage(file); // Store the selected file in state
    }

    //setPictureSrc(e.target.files[0])
  };

  const onUploadPicture = (e) => {
    e.preventDefault();

    if (pictureUpload) {
      console.log(pictureUpload);
      const formData = new FormData();
      if (selectedRow.length > 0) {
        formData.append('id', selectedRow[0].id);
      }
      formData.append('logo', pictureUpload, pictureUpload.name);
      formData.append('fileName', 'picture');
      formData.append('table', 'ingredients');
      formData.append('column', 'picture');

      callUploadFile(formData);

      return true;
    }
  };

  const callUploadFile = (formData) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/upload`, {
      method: 'PUT',
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }

        setPictureUpload(null);
        return res.json();
      })
      .then((data) => {
        //console.log(data);
        toast.success(data.message);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err);
      });
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: (row) => `${row.id}`,
      omit: true,
    },
    {
      name: 'Name',
      selector: (row) => row['ingredient'],
      sortable: true,
      center: false,
    },
    {
      name: 'Name(de)',
      selector: (row) => row['ingredient_de'],
      sortable: true,
      center: false,
    },
    {
      name: 'Unit',
      selector: (row) => `${row.unit}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Active',
      selector: (row) => `${row.active}`,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Ingredients List"
        parent="Products"
        title="Ingredients"
        subParent=""
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>{IngredientsTitle}</H5>
                <span>
                  Curently we are dealing One Product with variety of
                  Ingredients.
                </span>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md="6 mb-3">
                      <div className="mb-3">
                        <Label className="form-label" for="name">
                          Ingredient Name(English)
                        </Label>
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          placeholder="Ingredient Name"
                          name="name"
                          {...register('name', { required: true })}
                        />
                        <span className="d-block">
                          {errors.productName && 'Ingredient name is required'}
                        </span>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" for="optUnit">
                          Unit
                        </Label>

                        <Controller
                          name="optUnit"
                          control={control}
                          rules={{ required: true }} // Add a validation rule for required selection
                          render={({ field }) => (
                            <select
                              {...field}
                              className="form-select form-control"
                            >
                              <option value="">{'--Select Unit--'}</option>
                              {unitData.map((unit, index) => (
                                <option key={index} value={unit.id}>
                                  {unit.unit}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                        <span className="d-block txt-danger">
                          {errors.optUnit &&
                            (errors.optUnit.message
                              ? errors.optUnit.message
                              : 'Please select Unit')}
                        </span>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" for="description">
                          Description
                        </Label>
                        <textarea
                          className="form-control"
                          id="description"
                          rows={5}
                          placeholder="Description"
                          name="description"
                          {...register('description', {})}
                        ></textarea>
                      </div>

                      <div className="mb-3">
                        <div>
                          <Label className="form-label" for="name_de">
                            Description (German)
                          </Label>{' '}
                          &nbsp;
                          <Button
                            type="button"
                            className="btn py-0 px-2"
                            onClick={(e) => onTranslate(e, 'description')}
                          >
                            <i className="fa fa-language"></i>
                          </Button>
                        </div>
                        <textarea
                          className="form-control"
                          id="description_de"
                          rows={5}
                          placeholder="Description"
                          name="description_de"
                          {...register('description_de', {})}
                        ></textarea>
                      </div>
                    </Col>
                    <Col md="6 mb-3">
                      <div className="mb-3">
                        <div>
                          <Label className="form-label" for="name_de">
                            Ingredient Name(German)
                          </Label>{' '}
                          &nbsp;
                          <Button
                            type="button"
                            className="btn py-0 px-2"
                            onClick={(e) => onTranslate(e, 'name')}
                          >
                            <i className="fa fa-language"></i>
                          </Button>
                        </div>

                        <input
                          className="form-control"
                          id="name_de"
                          type="text"
                          name="name_de"
                          {...register('name_de')}
                        />
                      </div>

                      <div className="mb-3">
                        <div>
                          <Label className="form-label" for="picture">
                            Picture
                          </Label>
                          {pictureSrc ? (
                            <div>
                              <img
                                src={pictureSrc}
                                alt="Selected"
                                className="img-fluid"
                                style={{ height: '80px' }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{ width: '100px', height: '80px' }}
                            ></div>
                          )}

                          <input
                            className="form-control"
                            type="file"
                            name="picture"
                            accept="image/*"
                            onChange={(e) => onPictureChange(e)}
                          />
                          <Button
                            style={{ color: 'primary' }}
                            onClick={(e) => onUploadPicture(e)}
                          >
                            {'Upload'}
                          </Button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" for="description">
                          &nbsp;
                        </Label>
                        <div className="checkbox p-0">
                          <Input
                            className="form-check-input"
                            id="activeCheck"
                            type="checkbox"
                            checked={activeChecked}
                            onClick={() => {
                              setActiveChecked(!activeChecked);
                            }}
                            {...register('activeCheck')}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="activeCheck"
                          >
                            {'Active'}
                          </Label>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Btn type="submit" attrBtn={{ color: 'primary' }}>
                    {editing ? 'Update Ingredient' : 'Save Ingredient'}
                  </Btn>
                </Form>
              </CardBody>
              <CardBody>
                {selectedRow.length !== 0 && (
                  <>
                    <div className={'float-end w-100 d-flex bg-light-info'}>
                      <div
                        className={`d-flex align-items-center justify-content-between p-2`}
                      >
                        <Btn
                          attrBtn={{
                            color: 'primary',
                            onClick: () => handleEdit(),
                          }}
                        >
                          Edit
                        </Btn>
                      </div>
                    </div>
                  </>
                )}
                <DataTable
                  columns={tableColumns}
                  data={data}
                  striped={true}
                  center={true}
                  pagination
                  selectableRows
                  selectableRowsSingle
                  selectableRowsHighlight
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleSelect}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Ingredients;
