import React, { useEffect, useState } from "react";
import { useGetStripePaymentsQuery } from "../../features/stripeApiSlice";
import { Card, Container } from "react-bootstrap";
import { CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { Btn } from "../../AbstractElements";

const StripePayments = () => {
  const [data, setData] = useState([]);
  const [payment, setPayment] = useState({
    payment_id: "",
    payment_type: "",
    customer: "",
    customer_name: "",
    customer_email: "",
    status: "",
    paid: "",
    subscription: "",
    amount: "",
    invoice_pdf: "",
    main_coupon_name: "",
    sub_coupon_name: "",
    discount_value: "",
    default_payment_method: "",
    shipping_details: "",
    created: "",
    period_start: "",
    period_end: "",
  });
  const {
    data: stripePayments,
    isLoading,
    isSuccess,
    refetch,
  } = useGetStripePaymentsQuery();
  console.log("DATA SUBSCRIPTIONS", data);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        setData(stripePayments.data);
      }
    };
    fn();
  }, [stripePayments]);

  const handleAction = async (row) => {
    setPayment(row);
  };

  const tableColumns = [
    {
      name: "Id",
      selector: (row) => `${row.payment_id}`,
    },
    {
      name: "Type",
      selector: (row) => `${row.payment_type}`,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },
    {
      name: "Customer name",
      selector: (row) => `${row.customer_name}`,
      sortable: true,
      center: false,
    },
    {
      name: "Customer email",
      selector: (row) => `${row.customer_email}`,
      sortable: true,
      center: false,
    },
    {
      name: "Status",
      selector: (row) => `${row.status}`,
      sortable: true,
      center: false,
    },
    {
      name: "Paid",
      selector: (row) => `${row.paid}`,
      sortable: true,
      center: false,
    },
    {
      name: "Subscription",
      selector: (row) => `${row.subscription}`,
      sortable: true,
      center: false,
    },
    {
      name: "Amount",
      selector: (row) => `${row.amount}`,
      sortable: true,
      center: false,
    },
    {
      name: "Invoice Pdf",
      selector: (row) => (
        <a href={row.invoice_pdf} target="_blank" rel="noreferrer">
          Pdf link
        </a>
      ),
      sortable: true,
      center: false,
    },
    {
      name: "Main Coupon Name",
      selector: (row) => `${row.main_coupon_name}`,
      sortable: true,
      center: false,
    },
    {
      name: "Sub Coupon Name",
      selector: (row) => `${row.sub_coupon_name}`,
      sortable: true,
      center: false,
    },
    {
      name: "Discount Value",
      selector: (row) => `${row.discount_value}`,
      sortable: true,
      center: false,
    },
    {
      name: "Default Payment Method",
      selector: (row) => `${row.default_payment_method}`,
      sortable: true,
      center: false,
    },
    {
      name: "Address",
      selector: (row) =>
        `${row.shipping_details?.address?.line1} ${row.shipping_details?.address?.line2}`,
      sortable: true,
      center: false,
    },
    {
      name: "City",
      selector: (row) => `${row.shipping_details?.address?.city}`,
      sortable: true,
      center: false,
    },
    {
      name: "Country Code",
      selector: (row) => `${row.shipping_details?.address?.country}`,
      sortable: true,
      center: false,
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.created),
      cell: (row) => new Date(row.created).toDateString(),
      sortable: true,
      center: false,
    },
    {
      name: "Current Period Start",
      selector: (row) => new Date(row.period_start),
      cell: (row) => new Date(row.period_start).toDateString(),
      sortable: true,
      center: false,
    },
    {
      name: "Current Period End",
      selector: (row) => new Date(row.period_end),
      cell: (row) => new Date(row.period_end).toDateString(),
      sortable: true,
      center: false,
    },
    {
      name: "Action",
      width: "140px",
      cell: (row) => (
        <Btn attrBtn={{ color: "primary", onClick: () => handleAction(row) }}>
          {"View"}
        </Btn>
      ),
    },
  ];

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <h1>Stripe Payments</h1>
      <Container fluid>
        <Card>
          <CardBody>
            <DataTable
              columns={tableColumns}
              data={data}
              striped={true}
              center={true}
              pagination
              selectableRowsHighlight
              paginationComponentOptions={{
                noRowsPerPage: false, // Hide the dropdown that allows changing the number of rows per page
              }}
            />
          </CardBody>
        </Card>
        {payment?.payment_id && (
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-sm-6">
                  <div className="text-start">
                    <h3>Payment {payment.payment_id}</h3>
                    <p>
                      <strong>Type:</strong> {payment.payment_type}
                      <br />
                      <strong>Customer:</strong> {payment.customer}
                      <br />
                      <strong>Customer Name:</strong> {payment.customer_name}
                      <br />
                      <strong>Customer Email:</strong> {payment.customer_email}
                      <br />
                      <strong>Status:</strong> {payment.status}
                      <br />
                      <strong>Paid:</strong> {payment.paid ? "PAID" : "UNPAID"}
                      <br />
                      <strong>Subscription:</strong> {payment.subscription}
                      <br />
                      <strong>Amount:</strong> {payment.amount}
                      <br />
                      <strong>Invoice PDF:</strong>{" "}
                      <a
                        href={payment.invoice_pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Pdf link
                      </a>
                      <br />
                      <strong>Main Coupon Name:</strong>{" "}
                      {payment.main_coupon_name}
                      <br />
                      <strong>Sub Coupon Name:</strong>{" "}
                      {payment.sub_coupon_name}
                      <br />
                      <strong>Discount Value:</strong> {payment.discount_value}
                      <br />
                      <strong>Default Payment Method:</strong>{" "}
                      {payment.default_payment_method}
                      <br />
                      <strong>Address:</strong>{" "}
                      {payment.shipping_details?.address?.line1}{" "}
                      {payment.shipping_details?.address?.line2}
                      <br />
                      <strong>City:</strong>{" "}
                      {payment.shipping_details?.address?.city}
                      <br />
                      <strong>Country Code:</strong>{" "}
                      {payment.shipping_details?.address?.country}
                      <br />
                      <strong>Created Date:</strong>{" "}
                      {new Date(payment.created).toDateString()}
                      <br />
                      <strong>Current Period Start:</strong>{" "}
                      {new Date(payment.period_start).toDateString()}
                      <br />
                      <strong>Current Period End:</strong>{" "}
                      {new Date(payment.period_end).toDateString()}
                    </p>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  );
};

export default StripePayments;
