import { apiSlice } from "../app/api/apiSlice";

export const translateApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        translateText: builder.mutation({
            query: payload => ({
                url: `translate`,
                method: 'POST',
                body: { ...payload }
            })
        })
    })
})

export const {
    useTranslateTextMutation,
} = translateApiSlice