import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Row } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { useForm } from 'react-hook-form';


import { useGetSettingsQuery, useUpdateSettingsMutation } from '../../features/settingsApiSlice';

const ContactSettings = () => {

    const [updateSettings] = useUpdateSettingsMutation();

    const { data: fetchSettings, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetSettingsQuery({type:'contact', skip: false})
      
  
    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                
                if(fetchSettings.data) {
                    const s = fetchSettings.data;
                    console.log(s)
                    
                    setValue("txtAddress", s.address1);
                    setValue("txtPhone", s.phone);
                    setValue("txtWhatsApp", s.whatsapp);
                    setValue("txtEmail", s.email);
                    setValue("txtGoogleMap", s.google_map);
                    
                }
                    
            }
        }   
        fn();

    }, [fetchSettings]);
    
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
      const onSubmit = async (data) => {
        if (data !== '') {
            
            const settings = {
                type: 'contact',
                address1: data.txtAddress,
                phone: data.txtPhone,
                whatsapp: data.txtWhatsApp,
                email: data.txtEmail,
                google_map: data.txtGoogleMap
            };
            
            await updateSettings(settings)
            .then(async res => {

                console.log(res.data)
                //setProductName(res.data.product)

                toast.success("Settings saved");
                
            });
            
        } else {
            toast.error("Settings not saved");
        }
      };

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Website Settings' parent="Website Settings" title="Contact Settings" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>Contact Settings</H5>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                            
                            <Row>
                            <Col md='4 mb-3'>
                                <Label className='form-label' for='txtPhone'>Phone</Label>
                                <input className='form-control' id='txtPhone' type='text' name='txtPhone' {...register('txtPhone')} />
                            </Col>
                            
                            <Col md='4 mb-3'>
                                <Label className='form-label' for='txtWhatsApp'>WhatsApp</Label>
                                <input className='form-control' id='txtWhatsApp' type='text' name='txtWhatsApp' {...register('txtWhatsApp')} />
                            </Col>
                            
                            <Col md='4 mb-3'>
                                <Label className='form-label' for='txtEmail'>Email</Label>
                                <input className='form-control' id='txtEmail' type='text' name='txtEmail' {...register('txtEmail')} />
                            </Col>
                            </Row>
                            <Row>
                            <Col md='4 mb-3'>
                                <Label className='form-label' for='txtAddress'>Address</Label>
                                <textarea rows={5} className='form-control' id='txtAddress' type='text' name='txtAddress' {...register('txtAddress')}></textarea>
                            </Col>
                            <Col md='4 mb-3'>
                                <Label className='form-label' for='txtGoogleMap'>Google Map</Label>
                                <textarea rows={5} className='form-control' id='txtGoogleMap' type='text' name='txtGoogleMap' {...register('txtGoogleMap')}></textarea>
                            </Col>
                            </Row>
                            
                            <Btn attrBtn={{ color: 'primary' }}>{'Save Settings'}</Btn>
                        </Form>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Fragment>
    );
};

export default ContactSettings;