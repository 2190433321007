import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Breadcrumbs, Btn, H5, Image } from '../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Row, Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { useForm } from 'react-hook-form';

import { useGetSettingsQuery, useUpdateSettingsMutation, useUploadFileMutation } from '../../features/settingsApiSlice';


const BasicSettings = () => {

    const [updateSettings] = useUpdateSettingsMutation();
    const [logoUpload, setLogoUpload] = useState(null)
    const [favicoUpload, setFavicoUpload] = useState(null)
    const [logoSrc, setLogoSrc] = useState('')
    const [favSrc, setFavSrc] = useState('')

    const [uploadFile] = useUploadFileMutation();

    

    const { data: fetchSettings, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetSettingsQuery({type:'basic', skip: false})
      
  

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                
                if(fetchSettings.data) {
                    const s = fetchSettings.data;
                    
                    setValue("txtTitle", s.website_title);
                    setValue("txtLogo", s.logo);
                    setValue("txtFooterText", s.footer_text)

                    setValue("txtFavicon", s.fav_icon);
                    setValue("txtCopyRight", s.footer_copy_right);
                    
                    const src_logo = `${process.env.REACT_APP_BASE_URL}/${s.logo}`;
                    const src_fav = `${process.env.REACT_APP_BASE_URL}/${s.fav_icon}`;
                    console.log(src_logo, src_fav)
                    console.log(s.logo, s.fav_icon)
                    setLogoSrc(src_logo)
                    setFavSrc(src_fav)
                }
                    
            }
        }   
        fn();

    }, [fetchSettings]);




    const onPictureChange = (e, isLogo) => {

        const file = e.target.files[0];

        if (file) {
            // Read the selected file and set the image source
            const reader = new FileReader();
            reader.onload = (e) => {
                if( isLogo ) setLogoSrc(e.target.result);
                else setFavSrc(e.target.result);
            };
            reader.readAsDataURL(file);

            if( isLogo ) setLogoUpload(file)
            else setFavicoUpload(file)

        }

    }




    const onUploadLogo = async (e) => {
        e.preventDefault();
        if (logoUpload) {


            console.log(logoUpload)
            const formData = new FormData();
            formData.append("logo", logoUpload, logoUpload.name);
            formData.append("fileName", 'logo');
            formData.append("table", 'settings');
            formData.append("column", 'logo');

            callUploadFile(formData)


            // //Dispatch the action to upload the logo
            // await uploadFile(formData)
            // .then(res => {

            //     console.log(res)
            //     //setProductName(res.data.product)

            //     toast.success("Logo uploaded");
                
            // })
            // .catch(err => {
            //     console.log(err)
            //     console.log(err.code)
            // });

        }
    }

    
    const onUploadFavico = async (e) => {
        e.preventDefault();
        if (favicoUpload) {
            console.log(favicoUpload)
            const formData = new FormData();
            formData.append("logo", favicoUpload, favicoUpload.name);
            formData.append("fileName", 'fav');
            formData.append("table", 'settings');
            formData.append("column", 'fav_icon');

            callUploadFile(formData)

        }
    }


    const callUploadFile = (formData) => {
        
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/upload`,
            {
              method: "PUT",
              body: formData,
            }
          )
            .then((res) => {
              if (!res.ok) {
                return Promise.reject(res);
              }
              return res.json();
            })
            .then((data) => {
              console.log(data);
            })
            .catch((err) => {
              console.error(err);
            });


    }
    
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
      const onSubmit = async (data) => {
        if (data !== '') {
            
            const settings = {
                type: 'basic',
                website_title: data.txtTitle,
                logo: data.txtLogo,
                fav_icon: data.txtFavicon,
                footer_text: data.txtFooterText,
                footer_copy_right: data.txtCopyRight
            };
            console.log(settings)
            await updateSettings(settings)
            .then(async res => {

                console.log(res.data)
                //setProductName(res.data.product)

                toast.success("Settings saved");
                
            });
            
        } else {
            toast.error("Settings not saved");
        }
      };

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Website Settings' parent="Website Settings" title="Basic Settings" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>Basic Settings</H5>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='txtTitle'>Website Title</Label>
                                <input className='form-control' id='txtTitle' type='text' name='txtTitle' {...register('txtTitle')} />
                            </Col>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='txtCopyRight'>Footer Copyright</Label>
                                <input className='form-control' id='txtCopyRight' type='text' name='txtCopyRight' {...register('txtCopyRight')} />
                            </Col>
                            </Row>
                            <Row>
                            <Col md='10 mb-3'>
                                <Label className='form-label' for='txtFooterText'>Footer text</Label>
                                <textarea className='form-control' id='txtFooterText' name='txtFooterText' rows={5} {...register('txtFooterText')}></textarea>
                            </Col>
                            </Row>
                            
                            <Btn attrBtn={{ color: 'primary' }}>{'Save Settings'}</Btn>
                        </Form>
                        </CardBody>
                        <CardBody>
                        
                            <Row>
                            <Col md='5 mb-3'>
                                <Image attrImage={{
                                        className: "b-r-10 m-0",
                                        src: logoSrc,
                                        alt: "",
                                    }} />

                            <Form onSubmit={onUploadLogo} encType="multipart/form-data">
                                <Label className='form-label' for='txtLogo'>Logo</Label>
                                <input className='form-control' type="file" name='logo' accept="image/*"  onChange={e => onPictureChange(e, true)} />
                                <Button style={{ color: 'primary' }}>{'Upload Logo'}</Button>
                            </Form>
                            </Col>

                            <Col md='5 mb-3'>
                                <Image attrImage={{
                                        className: "b-r-10 m-0",
                                        src: favSrc,
                                        alt: "",
                                    }} />
                            <Form onSubmit={onUploadFavico} encType="multipart/form-data">    
                        
                                <Label className='form-label' for='txtFavicon'>Fav icon</Label>
                                <input className='form-control' type="file" name='favico' accept="image/*" onChange={e => onPictureChange(e, false)} />
                                <Button style={{ color: 'primary' }}>{'Upload Fav icon'}</Button>
                            </Form>
                            </Col>
                        
                            </Row>
                        
                        
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Fragment>
    );
};

export default BasicSettings;
