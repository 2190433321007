import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Breadcrumbs, Btn, H5 } from "../../../AbstractElements";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  Container,
  Input,
  Row,
  Button,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";

import { useNavigate } from "react-router";
import { useForm, Controller } from "react-hook-form";

import { useTranslateTextMutation } from "../../../features/translateApiSlice";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import {
  useGetBlogListQuery,
  useAddBlogMutation,
} from "../../../features/addons/blogsApiSlice";
import Select from "react-select";
const category = [
  { value: "blog", label: "Blog" },
  { value: "ingredient", label: "Ingredient" },
];

const Blogs = () => {
  const [content, setContent] = useState("");

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [activeChecked, setActiveChecked] = useState(true);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [editing, setEditing] = useState(false);
  const [selectCat, setSelectCat] = useState("");
  const history = useNavigate();

  const [addBlog] = useAddBlogMutation();
  const [translateText] = useTranslateTextMutation();

  const {
    data: fetchBlogs,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetBlogListQuery({ skip: false });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        console.log(fetchBlogs.data);
        setData(fetchBlogs.data);
      }
    };
    fn();
  }, [fetchBlogs]);

  const {
    register,
    reset,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      title_de: "",
      slug: "",
      date: "",
      meta_title: "",
      meta_description: "",
      activeCheck: "checked",
    },
  });

  const onSubmit = async (values) => {
    if (values !== "") {
      if (validate(values)) {
        const formData = {
          title: values.title,
          title_de: values.title_de,
          contents: "",
          contents_de: "",
          slug: values.slug,
          bdate: getValues("date"),
          meta_title: values.meta_title,
          category: selectCat,
          meta_description: values.meta_description,
          picture: "",
          active: activeChecked ? 1 : 0,
        };

        console.log(formData);

        try {
          const res = await addBlog(formData).unwrap();
          //console.log(res.data)
          let markers = [...data];
          markers.unshift(res.data);
          setData(markers);

          clears();

          toast.success("Data saved.");
        } catch (err) {
          if (err.status === 400) {
            toast.error(err.data?.message);
          } else {
            toast.error("Data not saved.");
          }
        }
      }
    } else {
      toast.error("Data not saved.");
    }
  };

  const validate = (values) => {
    if (getValues("date") === "") {
      setError("date", {
        type: "manual",
        message: "Please enter valid date.",
      });
      return false;
    }
    return true;
  };

  const onAddContents = (id) => {
    //console.log(id);
    history(`/admin/addons/blogs/add/${id}`);
  };

  const handleRowSelected = useCallback((state) => {
    //console.log(state)
    setSelectedRow(state.selectedRows);
    if (state.selectedCount === 0) {
      clears();
    }
  }, []);

  const clears = () => {
    setEditing(false);
    reset();
    // setValue('title', '')
    // setValue('title_de', '')
    // setValue('slug', '')
    // setValue('date', '')
    setActiveChecked(true);
    setSelectCat("");
  };

  const onTranslate = async (e) => {
    e.preventDefault();

    let text = getValues("title").trim();

    console.log(text);

    if (text !== "") {
      try {
        setValue("title_de", "");

        const response = await translateText({ contents: text }).unwrap();
        console.log(response.data);

        setValue("title_de", response.data);
      } catch (err) {
        console.log(err);
        //toast.error(err);
      }
    }
  };

  const tableColumns = [
    {
      name: "Id",
      selector: (row) => `${row.id}`,
      omit: true,
    },
    {
      name: "Title",
      selector: (row) => row["title"],
      sortable: true,
      center: false,
    },
    {
      name: "Date",
      selector: (row) => `${row.bdate}`,
      sortable: true,
      center: false,
    },
    {
      name: "Slug",
      selector: (row) => `${row.slug}`,
      sortable: true,
      center: true,
    },
    {
      name: "Category",
      selector: (row) => `${row?.category}`,
      sortable: true,
      center: true,
    },
    {
      name: "Active",
      selector: (row) => `${row.active}`,
      sortable: true,
      center: true,
      style: { width: "80" },
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          onClick={() => onAddContents(row.id)}
          className="btn btn-primary btn-sm px-1"
          style={{ whiteSpace: "nowrap" }}
          type="button"
        >
          {"Add Contents"}
        </Button>
      ),
      button: true,
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      // borderRadius: "23px",
      // height: "50px", // Adjust the height as needed
    }),
  };

  const handleCat = (selectedOption) => {
    console.log(selectedOption.value);
    setSelectCat(selectedOption.value);
    console.log(selectCat);
    // let target = { name: "gender", value: selectedOption.value };
    // updateInputs({ target });
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Blogs"
        parent="Addons"
        title="Blogs"
        subParent=""
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Col md="6">
                  <H5>Blogs</H5>
                </Col>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="title">
                        Title (English)
                      </Label>
                      <input
                        className="form-control"
                        id="title"
                        type="text"
                        placeholder="Title"
                        name="title"
                        {...register("title", { required: true })}
                      />
                      <span className="d-block">
                        {errors.title && "Title is required"}
                      </span>
                    </Col>
                    <Col md="6 mb-3">
                      <div>
                        <Label className="form-label" for="title_de">
                          Title (German)
                        </Label>
                        &nbsp;
                        <Button
                          type="button"
                          className="btn py-0 px-2"
                          onClick={(e) => onTranslate(e)}
                        >
                          <i className="fa fa-language"></i>
                        </Button>
                      </div>
                      <input
                        className="form-control"
                        id="title_de"
                        type="text"
                        placeholder="Title"
                        name="title_de"
                        {...register("title_de")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="slug">
                        Slug
                      </Label>
                      <input
                        className="form-control"
                        id="slug"
                        type="text"
                        placeholder="Slug"
                        name="slug"
                        {...register("slug", { required: true })}
                      />
                      <span className="d-block">
                        {errors.slug && "Slug is required"}
                      </span>
                    </Col>

                    <Col md="6 mb-3">
                      <Label className="form-label" for="date">
                        Date
                      </Label>
                      <input
                        className="form-control digits"
                        id="date"
                        name="date"
                        type="date"
                        {...register("date", { required: false })}
                      />
                      <span className="d-block txt-danger">
                        {errors.date && "Please enter valid date."}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="meta_title">
                        Meta Title
                      </Label>
                      <input
                        className="form-control"
                        id="meta_title"
                        type="text"
                        placeholder="Meta Title"
                        name="meta_title"
                        {...register("meta_title")}
                      />
                    </Col>

                    <Col md="6 mb-3">
                      <Label className="form-label" for="meta_description">
                        Meta Description
                      </Label>
                      <input
                        className="form-control"
                        id="meta_description"
                        name="meta_description"
                        placeholder="Meta Description"
                        type="text"
                        {...register("meta_description")}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="category">
                        Category
                      </Label>
                      <Select
                        options={category}
                        value={category.find((c) => c.value === selectCat)}
                        onChange={handleCat}
                      />
                    </Col>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="activeCheck">
                        &nbsp;
                      </Label>
                      <div className="checkbox p-0">
                        <Input
                          className="form-check-input"
                          id="activeCheck"
                          type="checkbox"
                          checked={activeChecked}
                          onClick={() => {
                            setActiveChecked(!activeChecked);
                          }}
                          {...register("activeCheck")}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="activeCheck"
                        >
                          {"Active"}
                        </Label>
                      </div>
                    </Col>
                  </Row>

                  <Btn attrBtn={{ color: "primary" }}>
                    {editing ? "Update" : "Save"}
                  </Btn>
                </Form>
              </CardBody>
              <CardBody>
                <DataTable
                  columns={tableColumns}
                  data={data}
                  striped={true}
                  center={true}
                  pagination
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleSelect}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Blogs;
