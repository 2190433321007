import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Breadcrumbs, Btn, H5, Image } from '../../../AbstractElements';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  Container,
  Input,
  Row,
  Button,
  NavLink,
} from 'reactstrap';
import CKEditor from 'react-ckeditor-component';
import { toast } from 'react-toastify';
import moment from 'moment';

import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useTranslateTextMutation } from '../../../features/translateApiSlice';

import {
  useGetTestimonialQuery,
  useUpdateTestimonialMutation,
} from '../../../features/addons/testimonialApiSlice';

const AddTestimonialRemarks = () => {
  const id = window.location.pathname.split('/').pop();

  const [content, setContent] = useState('');
  const [contentDe, setContentDe] = useState('');

  const [pictureUpload, setPictureUpload] = useState(null);
  const [pictureSrc, setPictureSrc] = useState();

  const [activeChecked, setActiveChecked] = useState(false);

  const [skip, setSkip] = useState(false);

  const [updateTestimonial] = useUpdateTestimonialMutation();
  const [translateText] = useTranslateTextMutation();

  const [testimonialId, setTestimonialId] = useState(id);

  const history = useNavigate();

  const {
    data: fetchTestimonial,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetTestimonialQuery({ id: testimonialId, skip: false }); // Set skip to false to force a fetch every time

  useEffect(() => {
    //console.log(testimonialId)

    refetch();
  }, [testimonialId]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        console.log(fetchTestimonial.data);
        const t = fetchTestimonial.data[0];

        setValue('name', t.name);
        setValue('designation', t.designation);
        setValue('company', t.company);

        setValue('date', t.tdate);

        setContent(t.remarks);
        setContentDe(t.remarks_de);
        setActiveChecked(t.active === 'Yes');

        const src_pic = `${process.env.REACT_APP_BASE_URL}/${t.picture}`;

        setPictureSrc(src_pic);
      }
    };
    fn();
  }, [fetchTestimonial, skip]);

  const {
    register,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      designation: '',
      company: '',
      date: '',
      active: 0,
    },
  });

  const onSubmit = async (values) => {
    if (values !== '') {
      if (validate(values)) {
        const formData = {
          id: testimonialId,
          name: values.name,
          designation: values.designation,
          company: values.company,
          remarks: content,
          remarks_de: contentDe,
          tdate: getValues('date'),
          picture: '',
          active: activeChecked ? 1 : 0,
        };

        console.log(formData);

        try {
          const res = await updateTestimonial(formData).unwrap();
          console.log(res.data);

          toast.success('Data updated.');
        } catch (err) {
          if (err.status === 400) {
            toast.error(err.data?.message);
          } else {
            toast.error('Data not saved.');
          }
        }
      }
    } else {
      toast.error('Data not saved.');
    }
  };

  const validate = (values) => {
    return true;
  };

  const onContentChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  const onContentChangeDe = (evt) => {
    const newContent = evt.editor.getData();
    setContentDe(newContent);
  };

  const onTranslate = async (e, element) => {
    e.preventDefault();

    let text;

    text = content;

    //console.log(text);

    if (text !== '') {
      try {
        setContentDe('');

        const response = await translateText({ contents: text }).unwrap();
        //console.log(response.data)

        setContentDe(response.data);
      } catch (err) {
        console.log(err);
        //toast.error(err);
      }
    }
  };

  const onPictureChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Read the selected file and set the image source
      const reader = new FileReader();
      reader.onload = (e) => {
        setPictureSrc(`${e.target.result}`);
      };
      reader.readAsDataURL(file);

      setPictureUpload(file);
      //setSelectedImage(file); // Store the selected file in state
    }

    //setPictureSrc(e.target.files[0])
  };

  const onUploadPicture = (e) => {
    e.preventDefault();

    if (pictureUpload) {
      //console.log(pictureUpload)
      const formData = new FormData();
      formData.append('id', testimonialId);
      formData.append('logo', pictureUpload, pictureUpload.name);
      formData.append('fileName', 'picture');
      formData.append('table', 'testimonials');
      formData.append('column', 'picture');

      callUploadFile(formData);

      return true;
    }
  };

  const callUploadFile = (formData) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/upload`, {
      method: 'PUT',
      body: formData,
    })
      .then((res) => {
        //console.log(res)
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        toast.success(data.message);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err);
      });
  };

  const onContentPagesClick = (e) => {
    e.preventDefault();
    history(`/admin/addons/testimonials`);
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Testimonials"
        parent="Addons"
        subParent="Testimonials"
        title="Add Remarks"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <H5>Add Remarks</H5>
                  </Col>
                  <Col md="6" style={{ textAlign: 'right' }}>
                    <NavLink
                      href="../"
                      style={{ whiteSpace: 'nowrap', color: '#7366ff' }}
                      onClick={(e) => onContentPagesClick(e)}
                    >
                      Testimonials
                    </NavLink>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="name">
                        Name
                      </Label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Name"
                        name="name"
                        {...register('name', { required: true })}
                      />
                      <span className="d-block">
                        {errors.name && 'Name is required'}
                      </span>

                      <Label className="form-label" for="designation">
                        Designation
                      </Label>
                      <input
                        className="form-control"
                        id="designation"
                        type="text"
                        placeholder="Designation"
                        name="designation"
                        {...register('designation', { required: true })}
                      />
                      <span className="d-block">
                        {errors.designation && 'Designation is required'}
                      </span>

                      <Label className="form-label" for="company">
                        Company
                      </Label>
                      <input
                        className="form-control"
                        id="company"
                        type="text"
                        placeholder="Company"
                        name="company"
                        {...register('company', { required: true })}
                      />
                      <span className="d-block">
                        {errors.company && 'Company is required'}
                      </span>

                      <Label className="form-label" for="date">
                        Date
                      </Label>
                      <input
                        className="form-control"
                        id="date"
                        type="date"
                        name="date"
                        {...register('date', { required: true })}
                      />
                      <span className="d-block">
                        {errors.date && 'Date is required'}
                      </span>

                      <div className="mt-3">
                        <div className="checkbox p-0">
                          <Input
                            className="form-check-input"
                            id="activeCheck"
                            type="checkbox"
                            checked={activeChecked}
                            onClick={() => {
                              setActiveChecked(!activeChecked);
                            }}
                            {...register('activeCheck')}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="activeCheck"
                          >
                            {'Active'}
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="picture">
                        Picture
                      </Label>
                      {pictureSrc ? (
                        <div>
                          <img src={pictureSrc} alt="Selected" height="200" />
                        </div>
                      ) : (
                        <div
                          className="background-image"
                          style={{ pictureSrc }}
                        ></div>
                      )}

                      <input
                        className="form-control"
                        type="file"
                        name="picture"
                        accept="image/*"
                        onChange={(e) => onPictureChange(e)}
                      />
                      <Button
                        style={{ color: 'primary' }}
                        onClick={(e) => onUploadPicture(e)}
                      >
                        {'Upload'}
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12 mb-3">
                      <Label className="form-label" for="description">
                        Remarks (English)
                      </Label>
                      <CKEditor
                        activeClass="p10"
                        content={content}
                        events={{
                          change: onContentChange,
                        }}
                      />
                    </Col>
                    <Col md="12 mb-3">
                      <div>
                        <Label className="form-label" for="description">
                          Remarks (German)
                        </Label>
                        &nbsp;
                        <Button
                          type="button"
                          className="btn py-0 px-2"
                          onClick={(e) => onTranslate(e, 'content')}
                        >
                          <i className="fa fa-language"></i>
                        </Button>
                      </div>
                      <CKEditor
                        activeClass="p10"
                        content={contentDe}
                        events={{
                          change: onContentChangeDe,
                        }}
                      />
                    </Col>
                  </Row>

                  <Btn attrBtn={{ color: 'primary' }}>{'Update'}</Btn>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddTestimonialRemarks;
