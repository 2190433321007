import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmationModal = (props) =>{

    const { isOpen, toggle, onConfirm, message } = props;

    const handleConfirm = () => {
        onConfirm();
        toggle();
    };
    
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader style={{paddingBottom: 0}} toggle={toggle}>Confirmation</ModalHeader>
          <ModalBody>
            {message}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleConfirm}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
    );
    
}

export default ConfirmationModal;