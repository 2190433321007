import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import ConfirmationModal from '../UiKits/ConfirmationModal';

import { useForm } from 'react-hook-form';
import { Username } from '../../Constant';

import { useGetCustomersQuery, useAddUserMutation, useUpdateUserMutation, useActiveCustomerMutation } from '../../features/users/userApiSlice';

const Customers = () => {

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [activeChecked, setActiveChecked] = useState(false)
    const [passwordChecked, setPasswordChecked] = useState(false)
    const [toggleSelect, setToggleSelect] = useState(false);
    const [editing, setEditing] = useState(false)

    const [addUser] = useAddUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [activeCustomer] = useActiveCustomerMutation();
    
    const { data: fetchUsers, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetCustomersQuery({email: localStorage.getItem('email'),
                    skip: false, // Set skip to false to force a fetch every time
                })
      
    
    useEffect(() => {
        refetch();
    }, [refetch]);
          
    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                //console.log(fetchUsers.data)
                setData(fetchUsers.data)
            }
        }   
        fn();

    }, [fetchUsers]);
    
    const handleRowSelected = useCallback(state => {
        
        //console.log(state)
        setSelectedRow(state.selectedRows);
        if(state.selectedCount === 0) {
            clears();
        }
        
    }, []);

    const clears = () => {
        setEditing(false)
        
        setActiveChecked(false)  
    }
    
    const handleEdit = () => {

        console.log(selectedRow[0])
        setEditing(true)
        setActiveChecked(selectedRow[0].active === 'Yes')
    };

    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const updateCustomer = (id, active) => {
        setData(prevData => {
          // Find the user with the specified ID
          const updatedData = prevData.map(c =>
            c.id === id ? { ...c, active: active } : c
          );
    
          return updatedData;
        });
      };

    const handleActive = async () => {

        console.log(selectedRow[0])
        if(selectedRow[0].admin === "User") {
            try {
                const response = await activeCustomer({email: selectedRow[0].email})

                console.log(response.data)
                if(response.data.status === 200) {

                    console.log(response.data.status)

                    const v = selectedRow[0].active === 'Yes' ? 'No' : 'Yes';

                    setData(prevData => {
                        // Find the user with the specified ID
                        const updatedData = prevData.map(c =>
                          c.id === selectedRow[0].id ? { ...c, active: v } : c
                        );
                  
                        return updatedData;
                    });
                      

                    
                    setEditing(false)
                    setToggleSelect(false)

                    toast.success("Customer status updated.");
                }
                else if(response.data.status === 201)
                    toast.error("Customer not updated.");
            }
            catch(err) {
                console.log(err.message)
                toast.error("Customer can't update.");
            }
        }
        else if(selectedRow[0].admin === "Admin") {
            toast.error("Customer can't update.");
        }
        
    };


    const tableColumns = [
        {
            name: 'Id',
            selector: row => `${row.id}`,
            omit: true
        },
        {
            name: 'Name',
            selector: row => row['name'],
            sortable: true,
            center: false,
        },
        {
            name: 'Username',
            selector: row => `${row.email}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Active',
            selector: row => `${row.active}`,
            sortable: true,
            center: true,
        },
        
    ];

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Customers' parent="Customer List" title="Customers" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>Customers List</H5>
                        </CardHeader>
                    
                        <CardBody>
                        <>
                            {(selectedRow.length !== 0) && <>
                            <div className={'float-end w-100 d-flex bg-light-info'}>
                                <div className={`d-flex align-items-center justify-content-between p-2`}>
                                    
                                    <Btn attrBtn={{ color: 'primary', onClick: () => handleActive() }}>Active/Inactive</Btn>
                                </div>

                            </div>
                                </>
                            }
                            <DataTable
                                columns={tableColumns}
                                data={data}
                                striped={true}
                                center={true}
                                pagination
                                selectableRows
                                selectableRowsSingle
                                selectableRowsHighlight
                                onSelectedRowsChange={handleRowSelected}
                                clearSelectedRows={toggleSelect}
                            />
                        </>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Fragment>
    );
};

export default Customers;