import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Breadcrumbs, Btn, H5, H6 } from '../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Row, Input } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { useGetGatewaysQuery, useUpdateGatewayMutation } from '../../features/paymentGatewayApiSlice';

const PaymentGateways = () => {
    const [updateGateway] = useUpdateGatewayMutation();

    const [currencyPaypal, setCurrencyPaypal] = useState(0)
    const [paymentPaypal, setPaymentPaypal] = useState(0)
    const [statusPaypal, setStatusPaypal] = useState(0)

    const [currencyStripe, setCurrencyStripe] = useState(0)
    const [paymentStripe, setPaymentStripe] = useState(0)
    const [statusStripe, setStatusStripe] = useState(0)

    const [selectedStatus, setSelectedStatus] = useState(0);


    const { data: fetchGateways, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetGatewaysQuery(); // Set skip to false to force a fetch every time
    
    useEffect(() => {
        //console.log(contentId)
        
        refetch();
    }, []);

    useEffect(() => {
        
        const fn = () => {
            if(isSuccess) {
                const d = fetchGateways.data;
                console.log(d)
                for(const row of d) {
                    if(row.company === 'paypal') {
                        setValue('clientSecret', row.clientSecret)
                        setValue('clientId', row.client_id)

                        setCurrencyPaypal(row.currency)
                        setPaymentPaypal(row.payment)
                        setStatusPaypal(row.active)
                    
                    }
                    else {

                        setValue('publishableKey', row.publishable_key)
                        setValue('secretKey', row.secret_key)

                        setCurrencyStripe(row.currency)
                        setPaymentStripe(row.payment)
                        setStatusStripe(row.active)
                    }
                    
                }
                
            }
        }   
        fn();

    }, [fetchGateways]);


    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
      const submitPaypal = (data) => {
        onSubmit(data, 'Paypal')
      }

      const submitStripe = (data) => {
        onSubmit(data, 'Stripe')
      }

      const onSubmit = async (data, company) => {
        if (data !== '') {
            
            const pgData = {
                clientSecret: company==='Paypal' ? data.clientSecret : '',
                client_id: company==='Paypal' ? data.clientId : '',
                currency: company==='Paypal' ? currencyPaypal : currencyStripe,
                payment: company==='Paypal' ? paymentPaypal : paymentStripe,
                publishable_key: company==='Paypal' ? '' : data.publishableKey,
                secret_key: company==='Paypal' ? '' : data.secretKey,
                company: company==='Paypal' ? 'paypal' : 'stripe',
                active: company==='Paypal' ? statusPaypal : statusStripe,
            };

            console.log(pgData)
            
            await updateGateway(pgData)
            .then(async res => {

                console.log(res)
                //setProductName(res.data.product)

                toast.success(`${company} settings saved`);
                
            })
            .catch(err => {
                console.log(err.message)
            });
            
        } else {
            toast.error(`${company} settings not saved`);
        }
      };

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Payment Gateways' parent="Website Settings" title="Payment Gateways" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>Paypal</H5>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(submitPaypal)}>
                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='clientSecret'>Client Secret</Label>
                                <input className='form-control' id='clientSecret' type='text' name='clientSecret' {...register('clientSecret', {required: true})} />
                            </Col>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='clientId'>Client Id</Label>
                                <input className='form-control' id='clientId' type='text' name='clientId' {...register('clientId')} />
                            </Col>
                            </Row>

                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='currencyPaypal'>Currency</Label>
                                <select type="select" name="currencyPaypal" className="form-select form-control" 
                                    value={currencyPaypal}
                                    onChange={e => setCurrencyPaypal(e.target.value)}>
                                    <option value='dollar'>{'Dollar'}</option>
                                    <option value='euro'>{'Euro'}</option>
                                </select>
                            </Col>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='paymentPaypal'>Payment</Label>
                                <select type="select" name="paymentPaypal" className="form-select form-control" 
                                    value={paymentPaypal}
                                    onChange={e => setPaymentPaypal(e.target.value)}>

                                    <option value='sandbox'>{'Sandbox'}</option>
                                    <option value='live'>{'Live'}</option>
                                </select>
                            </Col>
                            </Row>
                            
                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='statusPaypal'>Status</Label>
                                <select type="select" name="statusPaypal" className="form-select form-control" 
                                    value={statusPaypal}
                                    onChange={e => setStatusPaypal(e.target.value)}>

                                    <option value={1}>{'Active'}</option>
                                    <option value={0}>{'Inactive'}</option>
                                </select>
                            </Col>
                            
                            </Row>
                            <Btn attrBtn={{ color: 'primary' }}>{'Save'}</Btn>
                        </Form>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                        <H5>Stripe</H5>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(submitStripe)}>
                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='publishableKey'>Publishable Key</Label>
                                <input className='form-control' id='publishableKey' type='text' name='publishableKey' {...register('publishableKey', {required: true})} />
                            </Col>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='secretKey'>Secret Key</Label>
                                <input className='form-control' id='secretKey' type='text' name='secretKey' {...register('secretKey')} />
                            </Col>
                            </Row>

                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='optCurrencyStripe'>Currency</Label>
                                <select type="select" name="optCurrencyStripe" className="form-select form-control" 
                                    value={currencyStripe}
                                    onChange={e => setCurrencyStripe(e.target.value)}>
                                    <option value='dollar'>{'Dollar'}</option>
                                    <option value='euro'>{'Euro'}</option>
                                </select>
                            </Col>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='paymentStripe'>Payment</Label>
                                <select type="select" name="paymentStripe" className="form-select form-control" 
                                    value={paymentStripe}
                                    onChange={e => setPaymentStripe(e.target.value)}>

                                    <option value='sandbox'>{'Sandbox'}</option>
                                    <option value='live'>{'Live'}</option>
                                </select>
                            </Col>
                            </Row>
                            
                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='statusStripe'>Status</Label>
                                <select type="select" name="statusStripe" className="form-select form-control" 
                                    value={statusStripe}
                                    onChange={e => setStatusStripe(e.target.value)}>

                                    <option value={1}>{'Active'}</option>
                                    <option value={0}>{'Inactive'}</option>
                                </select>
                            </Col>
                            
                            </Row>
                            <Btn attrBtn={{ color: 'primary' }}>{'Save'}</Btn>
                        </Form>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Fragment>
    );
    
}

export default PaymentGateways;