import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Button, Row } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";

import { useForm } from 'react-hook-form';
import { Products } from '../../../Constant';

import { useGetProductsQuery, useAddProductMutation, useUpdateProductMutation } from '../../../features/addons/productApiSlice';
import { useTranslateTextMutation } from '../../../features/translateApiSlice';

const RiiseProducts = () => {

    const [product, setProduct] = useState(null)
    

    const [addProduct] = useAddProductMutation();
    const [updateProduct] = useUpdateProductMutation();
    const [translateText] = useTranslateTextMutation();

    const inputRef = useRef('');

    const { data: fetchProduct, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetProductsQuery({skip: false})
      
    const [productName, setProductName] = useState('')
  
    
    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                
                if(fetchProduct.data.length > 0) {
                    const p = fetchProduct.data[0];
                    setProduct(p)
                    setProductName(p.name)
                    setValue("productName", p.name);
                    setValue("productName_de", p.name_de);
                    setValue("price", p.price);
                    //console.log(p.product)
                    //inputRef.current.value = p.product;
                }
                    
            }
        }   
        fn();

    }, [fetchProduct]);
    
    
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
      const onSubmit = async (data) => {
        if (data !== '') {

            if(null === product) {
                const productData = {
                    name: data.productName, 
                    name_de: data.productName_de, 
                    price: data.price,
                    description: ''
                }

                await addProduct(productData)
                .then(async res => {

                    console.log(res.data)

                    toast.success("Product saved");
                    
                })
                .catch(err => {
                    console.log(err)

                    toast.error("Product not saved.");
                });
            }
            else if(null !== product) {

                const productData = {
                    id: product.id,
                    name: data.productName, 
                    name_de: data.productName_de, 
                    price: data.price,
                    description: ''
                }

                await updateProduct(productData)
                .then(async res => {

                    console.log(res.data)
                    //setProductName(res.data.product)

                    toast.success("Product updated");
                    
                })
                .catch(err => {
                    console.log(err)

                    toast.error("Product not updated.");
                });
            }
        } else {
            toast.error("Product not saved.");
        }
      };

      const onTranslate = async (e) => {
        e.preventDefault();

        const text = getValues('productName');

        console.log(text);

        if(text !== '') {
            try {

                setValue('productName_de', '')


                const response = await translateText({contents: text}).unwrap();
                console.log(response.data)

                setValue('productName_de', response.data)

            }
            catch(err) {
                console.log(err);
                //toast.error(err);
            }
            
        }
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Products List' parent="Addons" title="Products" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>{Products}</H5>
                        <span>
                            Curently we are dealing One Product with variety of Ingredients.
                        </span>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                            <Col md='4 mb-3'>
                                <Label className='form-label' for='productName'>
                                    Product Name
                                </Label>
                                <input className='form-control' id='productName' type='text' name='productName' {...register('productName', { required: true })} />
                                <span className='d-block'>{errors.productName && 'Product name is required'}</span>
                                
                            </Col>
                            <Col md='4 mb-3'>
                                <div>
                                    <Label className='form-label' for='productName_de'>Product Name (German)</Label>&nbsp;
                                    <Button type='button' className='btn py-0 px-2' onClick={e => onTranslate(e)}><i className="fa fa-language"></i></Button>
                                </div>
                                <input className='form-control' id='productName_de' type='text' name='productName_de' {...register('productName_de', { required: true })} />
                                
                            </Col>
                            
                            </Row>
                            <Row>
                            <Col md='4 mb-3'>
                                <Label className='form-label' for='price'>
                                    Product Price
                                </Label>
                                <input className='form-control' id='price' type='text' name='price' {...register('price', { required: true })} />
                                <span className='d-block'>{errors.price && 'Product price is required'}</span>
                            </Col>
                            </Row>
                            
                            <Btn attrBtn={{ color: 'primary' }}>{'Save Product'}</Btn>
                        </Form>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Fragment>
    );
};

export default RiiseProducts;