import React from 'react';
import Routers from './Route';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';

import { Provider } from 'react-redux';
import { store } from './app/store';


const App = () => (
  <div className='App'>
    <Provider store={store}>
      <CustomizerProvider>
        <AnimationThemeProvider>
          <Routers />
        </AnimationThemeProvider>

      </CustomizerProvider>
    </Provider>
  </div>
);

export default App;
