import React, { useEffect, useState } from "react";
import { useGetStripeCustomersQuery } from "../../features/stripeApiSlice";
import { Card, Container } from "react-bootstrap";
import { CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { Btn } from "../../AbstractElements";
const StripeCustomers = () => {
  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState({
    customer_id: "",
    name: "",
    email: "",
    phone: "",
  });
  const {
    data: stripeCustomers,
    isLoading,
    isSuccess,
    refetch,
  } = useGetStripeCustomersQuery();
  console.log("DATA CUSTOMERS", data);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        setData(stripeCustomers.data);
      }
    };
    fn();
  }, [stripeCustomers]);

  const handleAction = async (row) => {
    setCustomer(row);
  };

  const tableColumns = [
    {
      name: "Id",
      selector: (row) => `${row.customer_id}`,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      center: false,
    },
    {
      name: "Email",
      selector: (row) => `${row.email}`,
      sortable: true,
      center: false,
    },
    {
      name: "Phone Number",
      selector: (row) => `${row.phone}`,
      center: false,
    },
    {
      name: "Action",
      width: "140px",
      cell: (row) => (
        <Btn attrBtn={{ color: "primary", onClick: () => handleAction(row) }}>
          {"View"}
        </Btn>
      ),
    },
  ];

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <h1>Stripe Customer</h1>
      <Container fluid>
        <Card>
          <CardBody>
            <DataTable
              columns={tableColumns}
              data={data}
              striped={true}
              center={true}
              pagination
              selectableRowsHighlight
              paginationComponentOptions={{
                noRowsPerPage: false, // Hide the dropdown that allows changing the number of rows per page
              }}
            />
          </CardBody>
        </Card>
        {customer?.customer_id && (
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-sm-6">
                  <div className="text-start">
                    <h3>Customer {customer?.customer_id}</h3>
                    <p>
                      Name: {customer?.name}
                      <br />
                      Email: {customer?.email}
                      <br />
                      Phone number: {customer?.phone}
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  );
};

export default StripeCustomers;
