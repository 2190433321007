import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../../features/auth/authSlics';

//import { navigate } from '../../components/RootNavigator'

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}/api`, //'http://localhost:3000/api',
  //   credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    let token = getState().auth.token;
    if (null === token) {
      token = localStorage.getItem('token');
    }
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    // headers.set('Access-Control-Allow-Origin', 'http://localhost:3000');
    // headers.set('Access-Control-Allow-Credentials', 'true');
    // headers.set('preflightContinue', false);
    // testing by opening the CORS policy
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With, Content-Type, Accept'
    );

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // const method = extraOptions.method; // Extract the HTTP method
  // console.log(method)
  let result = await baseQuery(args, api, extraOptions);
  //console.log(result?.error?.data?.message)
  //console.log(result?.error)

  if (
    result?.error?.status === 401 &&
    result?.error?.data?.message === 'Unauthorized'
  ) {
    console.log('logging out');
    api.dispatch(logOut());

    // console.log('sending refresh token')

    // //const email = localStorage.getItem('email');
    // // send refresh token to get new access token
    // const refreshResult = await baseQuery('/admin/login/refresh', api, extraOptions);

    // console.log(refreshResult)

    // // const refreshResult = await api.fetch('/admin/login/refresh', {
    // //     method: 'POST',
    // //     body: {email: email},
    // //     headers: {
    // //         'Content-Type': 'application/json',
    // //     },
    // //     // Add any necessary headers or body for the refresh token request
    // //   });

    // if (refreshResult?.data) {
    //     const user = api.getState().auth.user
    //     // store the new token
    //     api.dispatch(setCredentials({ ...refreshResult.data, user }))
    //     // retry the original query with new access token
    //     result = await baseQuery(args, api, extraOptions)
    // } else {
    //     //api.dispatch(logOut())
    //     //navigate('Logout');
    //     console.log('should be logout')
    // }
  } else if (result?.error?.originalStatus === 401) {
    //api.dispatch(logOut())
    //navigate('Logout');
    console.log(result.error);
  }
  // else if (result?.error?.originalStatus === 405) {
  //     api.dispatch(logOut())
  //     navigate('Logout');
  // }
  // else if (result?.error?.originalStatus === 500) {

  //     api.dispatch(logOut())

  //     navigate('Logout');
  // }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
