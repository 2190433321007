import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import ConfirmationModal from '../UiKits/ConfirmationModal';

import { useForm } from 'react-hook-form';
import { Username } from '../../Constant';

import { useGetUsersQuery, useAddUserMutation, useUpdateUserMutation, useDeleteUserMutation } from '../../features/users/userApiSlice';

const Users = () => {

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [activeChecked, setActiveChecked] = useState(false)
    const [passwordChecked, setPasswordChecked] = useState(false)
    const [toggleSelect, setToggleSelect] = useState(false);
    const [editing, setEditing] = useState(false)

    const [addUser] = useAddUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    
    const { data: fetchUsers, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetUsersQuery({email: localStorage.getItem('email'),
                    skip: false, // Set skip to false to force a fetch every time
                })
      
    
    useEffect(() => {
        refetch();
    }, [refetch]);
          
    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                //console.log(fetchUsers.data)
                setData(fetchUsers.data)
            }
        }   
        fn();

    }, [fetchUsers]);
    
    const handleRowSelected = useCallback(state => {
        
        console.log(state)
        setSelectedRow(state.selectedRows);
        if(state.selectedCount === 0) {
            clears();
        }
        
    }, []);

    const clears = () => {
        setEditing(false)
        setValue('name', '')
        setValue('userName', '')
        setValue('password', '')
        setActiveChecked(false)  
    }
    
    const handleEdit = () => {

        console.log(selectedRow[0])
        setEditing(true)
        setValue('name', selectedRow[0].name)
        setValue('userName', selectedRow[0].email)
        setActiveChecked(selectedRow[0].active === 'Yes')
    };

    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleConfirmAction = () => {
        handleDelete()
    };

    const handleDelete = async () => {

        console.log(selectedRow[0])
        if(selectedRow[0].admin === "User") {
            try {
                const response = await deleteUser({email: selectedRow[0].email})

                console.log(response.data.message)
                if(response.data.message === "User deleted.") {

                    let markers = [...data];
                    let index = markers.findIndex(el => el.id === selectedRow[0].id);
                    markers.splice(index, 1)
                    
                    setData(markers)

                    setToggleSelect(false)

                    toast.success("User deleted.");
                }
                else 
                    toast.error("User not deleted.");
            }
            catch(err) {
                console.log(err.message)
                toast.error("User can't delete.");
            }
        }
        else if(selectedRow[0].admin === "Admin") {
            toast.error("User can't delete.");
        }
        
    };

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
      } = useForm();
      
    
      const onSubmit = async (values) => {
        if (values !== '') {
            
            const index = data.findIndex(el => 
                el.email === values.userName &&
                (editing && el.id !== selectedRow[0].id)
            );
            
            
            if(index > -1) {
                toast.error("Username already taken.");
            }
            else {

                const formData = {
                    name: values.name,
                    email: values.userName,
                    password: values.password,
                    active: activeChecked?1:0
                }
    
                try {

                    if(!editing) { // new user
                        const res = await addUser(formData).unwrap()
                        console.log(res.data)
                        let markers = [...data];
                        markers.push(res.data)
                        setData(markers)
            
                        clears();

                        toast.success("User saved.");
                    }
                    else { // edit user
                        
                        formData.id = selectedRow[0].id
                        

                        const res = await updateUser(formData).unwrap()
                        console.log(res.data)
                        let markers = [...data];
                        let index = markers.findIndex(el => el.id === formData.id);

                        markers[index] = res.data;
                        
                        setData(markers)
            
                        clears();

                        toast.success("User updated.");

                    }
                }
                catch(err) {
                    if(err.status === 400) {
                        toast.error(err.data?.message);
                    }
                    else {
                        toast.error("User not saved.");
                    }
                }
            }

        } else {
            toast.error("User not saved.");
        }
      };


    const tableColumns = [
        {
            name: 'Id',
            selector: row => `${row.id}`,
            omit: true
        },
        {
            name: 'Name',
            selector: row => row['name'],
            sortable: true,
            center: false,
        },
        {
            name: 'Username',
            selector: row => `${row.email}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Type',
            selector: row => `${row.admin}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Active',
            selector: row => `${row.active}`,
            sortable: true,
            center: true,
        },
        
    ];

    return (
        <Fragment>
            <Breadcrumbs mainTitle='User Management' parent="User List" title="Users" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>New User</H5>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                            <Col md='4 mb-3'>
                                <Label className='form-label' for='name'>Name</Label>
                                <input className='form-control' id='name' type='text' name='name' placeholder='Name' {...register('name', { required: true })} />
                                <span className='d-block txt-danger'>{errors.name && 'Name is required'}</span>
                            </Col>
                            <Col md='4 mb-3'>
                                <Label for='userName'>{Username}</Label>
                                <InputGroup>
                                <InputGroupText>{'@'}</InputGroupText>
                                <input className='form-control' id='userName' name='userName' type='text' placeholder='Username' {...register('userName', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })} />
                                <span className='d-block txt-danger w-100'>{errors.userName && 'User name is required'}</span>
                                </InputGroup>
                            </Col>
                            <Col md='4 mb-3'>
                                {editing ? 
                                    <div className='form-check'>
                                        <div className='checkbox p-0'>
                                        <Input className='form-check-input' id='passwordCheck' type='checkbox' checked={passwordChecked} onClick={() => {setPasswordChecked(!passwordChecked)}}/>
                                        <Label className='form-check-label mt-0 mb-0' htmlFor='passwordCheck'>
                                            {'Change password'}
                                        </Label>
                                        </div>
                                    </div>
                                :
                                <Label className='form-label' for='password'>Password</Label>
                                }
                                <input className='form-control' id='password' type='password' name='password' {...register('password', { required: !editing || (editing && passwordChecked), pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/ })} />
                                <span className='d-block txt-danger'>
                                    {(editing && passwordChecked) && errors.password && 'Password should contains a lowercase, a uppercase character and a digit and atleast 8 characters.'}
                                </span>
                            </Col>
                            </Row>
                            
                            <div>
                            <div className='form-check'>
                                <div className='checkbox p-0'>
                                <Input className='form-check-input' id='activeCheck' type='checkbox' checked={activeChecked} onClick={() => {setActiveChecked(!activeChecked)}} {...register('activeCheck')}/>
                                <Label className='form-check-label' htmlFor='activeCheck'>
                                    {'User Active'}
                                </Label>
                                </div>
                            </div>
                            </div>
                            <Btn attrBtn={{ color: 'primary'}}>{editing?'Update user':'Save user'}</Btn>
                        </Form>
                        </CardBody>
                    {/* </Card>
                    <Card> */}
                        {/* <CardHeader><H5>Users List</H5></CardHeader> */}
                        <CardBody>
                        <H5>Users List</H5>
                        <>
                            {(selectedRow.length !== 0) && <>
                            <div className={'float-end w-100 d-flex bg-light-info'}>
                                <div className={`d-flex align-items-center justify-content-between p-2`}>
                                    
                                    <Btn attrBtn={{ color: 'primary', onClick: () => handleEdit() }}>Update</Btn>
                                </div>

                                <div className={`d-flex align-items-center justify-content-between p-2`}>
                                
                                    <Btn attrBtn={{ color: 'danger', onClick: () => toggleModal() }}>Delete</Btn>
                                </div>
                            </div>
                                </>
                            }
                            <DataTable
                                columns={tableColumns}
                                data={data}
                                striped={true}
                                center={true}
                                pagination
                                selectableRows
                                selectableRowsSingle
                                selectableRowsHighlight
                                onSelectedRowsChange={handleRowSelected}
                                clearSelectedRows={toggleSelect}
                            />
                        </>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>

            <ConfirmationModal
                message={'Are you sure to delete the user?'}
                isOpen={modalOpen}
                toggle={toggleModal}
                onConfirm={handleConfirmAction}
            />

            <ToastContainer/>
        </Fragment>
    );
};

export default Users;

