import React, {useEffect, useState} from 'react'
import {Button, Col, Label, Row} from "reactstrap";
import {useTranslateTextMutation} from "../../../features/translateApiSlice";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";

const Answer = ({data, data_de, values}) => {

    const [pictureUpload, setPictureUpload] = useState(null)
    const [pictureSrc, setPictureSrc] = useState()
    const [answerCode, setAnswerCode] = useState()
    const [translateText] = useTranslateTextMutation();

    useEffect(() => {

        if(data && data_de) {

            setValue('text', data.answer)
            setValue('text_de', data_de.answer)
            setAnswerCode(data.code)

            values.code = data.code;
            values.answer = data.answer
            values.answerDe = data_de.answer

        }
        if(data && data.img && data.img !== '') {
            values.img = data.img
            const src_pic = `${process.env.REACT_APP_BASE_URL}/${data.img}`;

            setPictureSrc(src_pic);
        }
    }, []);

    const {
        register,
        setValue,
        getValues,
        reset,
        control,
        setError,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            text: '',
            text_de: ''
        }
    });

    const onTextChange = (e) => {
        e.preventDefault()

        values.answer = e.target.value

    }

    const onTextChangeDe = (e) => {
        e.preventDefault()

        values.answerDe = e.target.value

    }

    const onTranslate = async () => {

        const contents = getValues('text').trim();

        if(contents !== '') {
            try {
                setValue('text_de', '');

                const response = await translateText({contents}).unwrap();

                setValue('text_de', response.data);
                values.answerDe = response.data;
            }
            catch(err) {
                console.log(err);
                //toast.error(err);
            }

        }
    }

    const onPictureChange = (e) => {

        const file = e.target.files[0];

        if (file) {
            // Read the selected file and set the image source
            const reader = new FileReader();
            reader.onload = (e) => {
                setPictureSrc(e.target.result);
            };
            reader.readAsDataURL(file);

            setPictureUpload(file)
        }

    }

    const onUploadPicture = (e) => {
        e.preventDefault();

        if (pictureUpload) {
            console.log(pictureUpload)
            const formData = new FormData();
            formData.append("answer_code", answerCode);
            formData.append("logo", pictureUpload, pictureUpload.name);
            formData.append("fileName", 'image');
            formData.append("table", 'quiz_answer_attr');
            formData.append("column", 'img');

            callUploadFile(formData)

            return true;
        }
    }


    const callUploadFile = (formData) => {

        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/upload`,
            {
                method: "PUT",
                body: formData,
            }
        )
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res);
            }

            setPictureUpload(null)
            //toast.success("Picture uploaded.");
            return res.json();
        })
        .then((data) => {
            //console.log(data);
            values.img = data.filename;
            toast.success(data.message);
        })
        .catch((err) => {
            console.error(err);
            toast.error(err);
        });

    }


    const badgeClass = (type) => {
        if(type === 'img-button') return 'badge-info';
        if(type === 'img-checkbox') return 'badge-light-success';
        if(type === 'checkbox') return 'badge-primary';
        if(type === 'button') return 'badge-light-warning';
        if(type === 'label') return 'badge-light-secondary';
        if(type === 'text') return 'badge-danger';

        return 'badge-light';
    }

    return(
        <Row>
            <Col md='4 mb-3'>
                <Label className='form-label '><span className={`badge ${badgeClass(data.type)}`}>{data.type}</span></Label>
                <input className='form-control' id='text' type='text' {...register('text', {onChange: (e) => onTextChange(e)})} />
            </Col>
            <Col md='1 mb-3'>
                <Label className='form-label'>&nbsp;</Label>
                <Button type='button' className='form-control btn' onClick={onTranslate}><i className="fa fa-language"></i></Button>
            </Col>
            <Col md='4 mb-3'>

                <Label className='form-label'>&nbsp;</Label>
                <input className='form-control' id='text_de' type='text' {...register('text_de', {onChange: (e) => onTextChangeDe(e)})} />

            </Col>
            <Col md='1 mb-3'>
                {pictureSrc && <img src={pictureSrc} alt="Image" className='img-responsive' style={{maxWidth: '65px'}}/>}
            </Col>
            <Col md='2 mb-3'>
                {pictureSrc &&
                    <>
                    <input className='' type="file" name='picture' accept="image/*" onChange={e => onPictureChange(e)}/>
                    <Button style={{color: 'primary'}} onClick={e => onUploadPicture(e)}>{'Upload'}</Button>
                    </>
                }
            </Col>
        </Row>
    );
}
export default Answer;
