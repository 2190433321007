import { apiSlice } from "../app/api/apiSlice";

export const stripeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStripeSubscriptions: builder.query({
      query: () => ({ url: `stripe/subscriptions` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getStripeCustomers: builder.query({
      query: () => ({ url: `stripe/customers` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    getStripePayments: builder.query({
      query: () => ({ url: `stripe/payments` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
  }),
});

export const {
  useGetStripeCustomersQuery,
  useGetStripePaymentsQuery,
  useGetStripeSubscriptionsQuery,
} = stripeApiSlice;
