import React, { useEffect, useState } from "react";
import { useGetStripeSubscriptionsQuery } from "../../features/stripeApiSlice";
import { Card, Container } from "react-bootstrap";
import { CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { Btn } from "../../AbstractElements";
import axios from "axios";

const StripeSubscriptions = () => {
  const [data, setData] = useState([]);
  const [subscription, setSubscription] = useState({
    subscription_id: "",
    stripe_product: "",
    customer: "",
    last_invoice_id: "",
    price_id: "",
    status: "",
    voucher_code: "",
    start_date: "",
    current_period_start: "",
    current_period_end: "",
  });
  const {
    data: stripeSubs,
    isLoading,
    isSuccess,
    refetch,
  } = useGetStripeSubscriptionsQuery();
  console.log("DATA SUBSCRIPTIONS", data);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        setData(stripeSubs.data);
      }
    };
    fn();
  }, [stripeSubs]);

  const handleAction = async (row) => {
    setSubscription(row);
  };

  const tableColumns = [
    {
      name: "Id",
      selector: (row) => `${row.subscription_id}`,
    },
    {
      name: "Product Name",
      selector: (row) => row.stripe_product,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => `${row.customer}`,
      sortable: true,
      center: false,
    },
    {
      name: "Last Invoice",
      selector: (row) => `${row.last_invoice_id}`,
      sortable: true,
      center: false,
    },
    {
      name: "Stripe Price Id",
      selector: (row) => `${row.price_id}`,
      sortable: true,
      center: false,
    },
    {
      name: "Status",
      selector: (row) => `${row.status}`,
      sortable: true,
      center: false,
    },
    {
      name: "Voucher Code",
      selector: (row) => `${row.voucher_code}`,
      sortable: true,
      center: false,
    },
    {
      name: "Start Date",
      selector: (row) => new Date(row.start_date),
      sortable: true,
      center: false,
      cell: (row) => new Date(row.start_date).toDateString(),
    },
    {
      name: "Current Period Start",
      selector: (row) => new Date(row.current_period_start),
      sortable: true,
      center: false,
      cell: (row) => new Date(row.current_period_start).toDateString(),
    },
    {
      name: "Current Period End",
      selector: (row) => new Date(row.current_period_end),
      sortable: true,
      center: false,
      cell: (row) => new Date(row.current_period_end).toDateString(),
    },
    {
      name: "Action",
      width: "140px",
      cell: (row) => (
        <Btn attrBtn={{ color: "primary", onClick: () => handleAction(row) }}>
          {"View"}
        </Btn>
      ),
    },
  ];

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <h1>Stripe Subscriptions</h1>
      <Container fluid>
        <Card>
          <CardBody>
            <DataTable
              columns={tableColumns}
              data={data}
              striped={true}
              center={true}
              pagination
              selectableRowsHighlight
              paginationComponentOptions={{
                noRowsPerPage: false, // Hide the dropdown that allows changing the number of rows per page
              }}
            />
          </CardBody>
        </Card>
        {subscription?.subscription_id && (
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-sm-6">
                  <div className="text-start">
                    <h3>Subscription {subscription?.subscription_id}</h3>
                    <p>
                      <strong>Stripe Product:</strong>{" "}
                      {subscription?.stripe_product}
                      <br />
                      <strong>Last Invoice:</strong>{" "}
                      {subscription?.last_invoice_id}
                      <br />
                      <strong>Stripe Price ID:</strong> {subscription?.price_id}
                      <br />
                      <strong>Status:</strong> {subscription?.status}
                      <br />
                      <strong>Voucher Code:</strong>{" "}
                      {subscription?.voucher_code}
                      <br />
                      <strong>Start Date:</strong>{" "}
                      {new Date(subscription?.start_date).toDateString()}
                      <br />
                      <strong>Current Period Start:</strong>{" "}
                      {new Date(
                        subscription?.current_period_start
                      ).toDateString()}
                      <br />
                      <strong>Current Period End:</strong>{" "}
                      {new Date(
                        subscription?.current_period_end
                      ).toDateString()}
                    </p>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  );
};

export default StripeSubscriptions;
