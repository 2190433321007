import React from 'react';
import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Loader from '../Layout/Loader';
import LayoutRoutes from '../Route/LayoutRoutes';
import Signin from '../Auth/Signin';
import PrivateRoute from './PrivateRoute';
import { classes } from '../Data/Layouts';
import { useNavigate } from 'react-router-dom';

import { setCredentials } from '../features/auth/authSlics';
import { useDispatch } from 'react-redux';
import { useSendLogoutMutation } from '../features/auth/authApiSlice';
import { logOut } from '../features/auth/authSlics';

const Routers = () => {
  const [login, setLogin] = useState(false);

  //const user = useState(JSON.parse(localStorage.getItem("user")));
  const [authenticated, setAuthenticated] = useState(false);
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === 'compact-wrapper'
  );
  const layout =
    localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [sendLogout, { isLoading }] = useSendLogoutMutation();
  const dispatch = useDispatch();
  //const history = useNavigate();

  useEffect(() => {
    let abortController = new AbortController();
    const tokenValue = localStorage.getItem('token') || '';
    const expires_in = localStorage.getItem('expires_in');

    //console.log('token: ' + tokenValue);

    const currentTime = new Date().getTime();

    if (expires_in > currentTime && tokenValue !== '') {
      const credentials = { access_token: tokenValue, expires_in: 0 };
      dispatch(setCredentials({ ...credentials, storage: false }));

      setLogin(JSON.parse(localStorage.getItem('login')));
      setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));
    } else {
      if (tokenValue && tokenValue !== '') {
        //sendLogout();
      }

      console.log('logout');
      dispatch(logOut());

      //history(`/admin/login`);
    }

    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);


  return (
    <BrowserRouter basename={'/'}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={'/'} element={<PrivateRoute />}>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}`}
              element={<Navigate to={`/admin/dashboard`} />}
            />
            <Route
              exact
              path={`/`}
              element={<Navigate to={`/admin/dashboard`} />}
            />

            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>

          <Route exact path={`/admin/login`} element={<Signin />} />
          {/* {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))} */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
