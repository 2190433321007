import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Row, Input } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { useForm } from 'react-hook-form';


import { useGetSettingsQuery, useUpdateSettingsMutation } from '../../features/settingsApiSlice';

const EmbededScriptSettings = () => {

    const [updateSettings] = useUpdateSettingsMutation();
    const [googleStatus, setGoogleStatus] = useState(0);
    const [metaStatus, setMetaStatus] = useState(0);
    const [omnisendStatus, setOmnisendStatus] = useState(0);

    const { data: fetchSettings, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetSettingsQuery({type:'embededscript', skip: false})
      
  
    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                
                if(fetchSettings.data) {
                    const s = fetchSettings.data;
                    console.log(s)
                    
                    setGoogleStatus(s.analytics_status);
                    setMetaStatus(s.meta_status);
                    setOmnisendStatus(s.omnisend_status);

                    setValue("txtAnalyticsId", s.analytics_id);
                    setValue("txtMetaId", s.meta_id);
                    setValue("txtOmnisendId", s.omnisend_id);

                    //'meta_status', 'meta_id', 'omnisend_status', 'omnisend_id'
                    
                }
                    
            }
        }   
        fn();

    }, [fetchSettings]);
    
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
      const onSubmit = async (data) => {
        if (data !== '') {
            
            const settings = {
                type: 'embededscript',
                analytics_status: googleStatus,
                analytics_id: data.txtAnalyticsId,

                meta_status: metaStatus,
                meta_id: data.txtMetaId,

                omnisend_status: omnisendStatus,
                omnisend_id: data.txtOmnisendId,
            };
            console.log(settings)
            
            await updateSettings(settings)
            .then(async res => {

                console.log(res)
                //setProductName(res.data.product)

                toast.success("Settings saved");
                
            })
            .catch(err => {
                console.log(err.message)
            });
            
        } else {
            toast.error("Settings not saved");
        }
      };

    return (
        <Fragment>
            <Breadcrumbs mainTitle='Website Settings' parent="Website Settings" title="Embed Script" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>Embed Script</H5>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='optAnalyticsStatus'>Google Analytics Status</Label>
                                <select name="optAnalyticsStatus" className="form-select form-control"
                                    value={googleStatus}
                                    onChange={e => setGoogleStatus(e.target.value)}>

                                    <option value={1}>{'Yes'}</option>
                                    <option value={0}>{'No'}</option>
                                </select>
                            </Col>
                            <Col md='5 mb-3'>
                                <Label className='form-label' for='txtAnalyticsId'>Google Analytics Id</Label>
                                <input className='form-control' id='txtAnalyticsId' type='text' name='txtAnalyticsId' {...register('txtAnalyticsId')} />
                            </Col>
                            </Row>

                            <Row>
                                <Col md='5 mb-3'>
                                    <Label className='form-label' for='optMetaStatus'>META Analytics Status</Label>
                                    <select name="optMetaStatus" className="form-select form-control"
                                            value={metaStatus}
                                            onChange={e => setMetaStatus(e.target.value)}>

                                        <option value={1}>{'Yes'}</option>
                                        <option value={0}>{'No'}</option>
                                    </select>
                                </Col>
                                <Col md='5 mb-3'>
                                    <Label className='form-label' for='txtMetaId'>META Analytics Id</Label>
                                    <input className='form-control' id='txtMetaId' type='text' name='txtMetaId' {...register('txtMetaId')} />
                                </Col>
                            </Row>


                            <Row>
                                <Col md='5 mb-3'>
                                    <Label className='form-label' for='optOmnisendStatus'>Omnisend Analytics Status</Label>
                                    <select name="optOmnisendStatus" className="form-select form-control"
                                            value={omnisendStatus}
                                            onChange={e => setOmnisendStatus(e.target.value)}>

                                        <option value={1}>{'Yes'}</option>
                                        <option value={0}>{'No'}</option>
                                    </select>
                                </Col>
                                <Col md='5 mb-3'>
                                    <Label className='form-label' for='txtOmnisendId'>Omnisend Analytics Id</Label>
                                    <input className='form-control' id='txtOmnisendId' type='text' name='txtOmnisendId' {...register('txtOmnisendId')} />
                                </Col>
                            </Row>

                            <Btn attrBtn={{ color: 'primary' }}>{'Save Settings'}</Btn>
                        </Form>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Fragment>
    );
};

export default EmbededScriptSettings;