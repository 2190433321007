import { apiSlice } from "../app/api/apiSlice";

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrders: builder.query({
            query: () => ({url: `orders`}),
            validateStatus: (response, result) => {
                console.log(result)
                return response.status === 200 && !result.isError
            },
        }),
        orderDetails: builder.mutation({
            query: payload => ({
                url: `order/details`,
                method: 'POST',
                body: { ...payload }
            })
        }),
        addStatus: builder.mutation({
        query: payload => ({
            url: `order/status`,
            method: 'POST',
            body: { ...payload }
        }),
    }),
    })
})

export const {
    useGetOrdersQuery,
    useOrderDetailsMutation,
    useAddStatusMutation,
} = ordersApiSlice