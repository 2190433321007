import { apiSlice } from "../app/api/apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSettings: builder.query({
            query: payload => ({
                url: 'settings',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        updateSettings: builder.mutation({
            query: payload => ({
                url: `settings`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
        uploadFile: builder.mutation({
            query: formData => ({
                url: `upload`,
                method: 'PUT',
                body: formData
            }),
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }),
    })
})

export const {
    useGetSettingsQuery,
    useUpdateSettingsMutation,
    useUploadFileMutation
} = settingsApiSlice