import { apiSlice } from "../../app/api/apiSlice";

export const ContentPagesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContentPageList: builder.query({
            query: () => 'contentpage/list',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        getPageContent: builder.query({
            query: (payload) => ({
                url: 'contentpagebyid',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        getContentsByTagHome: builder.query({
            query: () => `contentpage/listbytag?tags=home`,
            validateStatus: (response, result) => {
                console.log(result)
                return response.status === 200 && !result.isError
            },
        }),
        getContentsByTagAbout: builder.query({
            query: () => `contentpage/listbytag?tags=about`,
            validateStatus: (response, result) => {
                console.log(result)
                return response.status === 200 && !result.isError
            },
        }),
        getContentsByTagPartner: builder.query({
            query: () => `contentpage/listbytag?tags=partner`,
            validateStatus: (response, result) => {
                console.log(result)
                return response.status === 200 && !result.isError
            },
        }),
        getContentsByTagHeaderImage: builder.query({
            query: () => `contentpage/listbytag?tags=HeaderImage`,
            validateStatus: (response, result) => {
                console.log(result)
                return response.status === 200 && !result.isError
            },
        }),
        addContentPage: builder.mutation({
            query: payload => ({
                url: 'contentpage',
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateContentPage: builder.mutation({
            query: payload => ({
                url: `contentpage`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
        
        })
    })

export const {
    useGetContentPageListQuery,
    useGetPageContentQuery,
    useGetContentsByTagHomeQuery,
    useGetContentsByTagAboutQuery,
    useGetContentsByTagPartnerQuery,
    useGetContentsByTagHeaderImageQuery,
    useAddContentPageMutation,
    useUpdateContentPageMutation,
} = ContentPagesApiSlice