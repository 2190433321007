import { apiSlice } from "../../app/api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: payload => ({
                url: 'admin/users',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        getUser: builder.query({
            query: payload => ({
                url: 'admin/user',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        addUser: builder.mutation({
            query: payload => ({
                url: 'admin/register',
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateUser: builder.mutation({
            query: payload => ({
                url: `admin/user`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
        deleteUser: builder.mutation({
            query: payload => ({
                url: `admin/user`,
                method: 'DELETE',
                body: { ...payload }
            })
        }),

        getCustomers: builder.query({
            query: payload => ({
                url: 'admin/users',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        activeCustomer: builder.mutation({
            query: payload => ({
                url: `customer/active`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
        getInquiry: builder.query({
            query: payload => ({
                url: 'inquirybyid',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        getInquiries: builder.query({
            query: payload => ({
                url: 'inquiry/list',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
    })
})

export const {
    useGetUserQuery,
    useGetUsersQuery,
    useAddUserMutation,
    useDeleteUserMutation,
    useUpdateUserMutation,

    useGetCustomersQuery,
    useActiveCustomerMutation,
    useGetInquiryQuery,
    useGetInquiriesQuery,
} = userApiSlice