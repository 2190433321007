import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, P } from '../AbstractElements';
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
} from '../Constant';

import { useNavigate } from 'react-router-dom';
import man from '../assets/images/dashboard/profile.png';

import { ToastContainer, toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setCredentials, logOut } from '../features/auth/authSlics';
import { useLoginMutation } from '../features/auth/authApiSlice';

const Signin = ({ selected }) => {
  const [email, setEmail] = useState('asim@mail.com');
  const [password, setPassword] = useState('Abc123456!');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  const [value, setValue] = useState(localStorage.getItem('profileURL' || man));
  const [name, setName] = useState(localStorage.getItem('Name'));

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('profileURL', man);
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();

    if (email !== '' && password !== '') {
      try {
        const userData = await login({
          email: email,
          password: password,
        }).unwrap();

        //console.log(userData)

        dispatch(setCredentials({ ...userData, storage: true }));

        const { user, expires_in } = userData;

        const auth0_profile = {
          name: user.name,
          email: user.email,
          picture: man,
        };
        setName(user.name);
        setValue(man);

        localStorage.setItem('login', JSON.stringify(true));
        localStorage.setItem('authenticated', JSON.stringify(true));

        const expiresIn = calculateExpiration(expires_in);
        localStorage.setItem('expires_in', expiresIn);
        localStorage.setItem('auth0_profile', JSON.stringify(auth0_profile));
        localStorage.setItem('email', user.email);
        localStorage.setItem('Name', JSON.stringify(user.name));

        history(`/admin/dashboard`);
        toast.success('Successfully logged in!..');
      } catch (error) {
        //console.log(err)

        if (error.response) {
          console.log(error.message);
          console.log(error.response);
          //do something
        } else if (error.request) {
          console.log(error.request);
          //do something else
        } else if (error.message) {
          console.log(error.message);
          //do something other than the other two
        }
        toast.error('You enter wrong password or username!..');
      }
    } else {
      toast.error('You enter wrong password or username!..');
    }

    // setValue(man);
    // setName("Emay Walter");
    // if (email === "test@gmail.com" && password === "test123") {
    //   localStorage.setItem("login", JSON.stringify(true));
    //   history(`/admin/dashboard/default/${layoutURL}`);
    //   toast.success("Successfully logged in!..");
    // } else {
    //   toast.error("You enter wrong password or username!..");
    // }
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <H4 className="auth-content">
                    {selected === 'simpleLogin' ? '' : 'Sign In'}
                  </H4>
                  <P>{'Enter your email & password to login'}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      className="form-control"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input
                        className="form-control"
                        type={togglePassword ? 'text' : 'password'}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? '' : 'show'}></span>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    {/* <div className="checkbox">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <a className="link" href="#javascript">
                      {ForgotPassword}
                    </a> */}
                    <Btn
                      attrBtn={{
                        color: 'primary',
                        className: 'd-block w-100 mt-2',
                        onClick: (e) => loginAuth(e),
                      }}
                    >
                      {SignIn}
                    </Btn>
                  </div>
                  {/* <OtherWay /> */}
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;

function calculateExpiration(ttl) {
  console.log(ttl);
  let timeStamps = 0;
  // Check if ttl is a number or a string
  if (typeof ttl === 'number') {
    // If it's a number, treat it as seconds
    const now = new Date();
    const expiration = new Date(now.getTime() + ttl * 1000); // Convert seconds to milliseconds
    timeStamps = expiration;
  } else if (typeof ttl === 'string') {
    // If it's a string, parse it to extract the duration and unit
    const duration = parseInt(ttl);
    const unit = ttl.slice(-1); // Get the last character (e.g., 'h', 'd')

    if (isNaN(duration)) {
      throw new Error('Invalid TTL format');
    }

    const now = new Date();

    switch (unit) {
      case 's':
        timeStamps = new Date(now.getTime() + duration * 1000);
      case 'm':
        timeStamps = new Date(now.getTime() + duration * 60000);
      case 'h':
        timeStamps = new Date(now.getTime() + duration * 3600000);
      case 'd':
        timeStamps = new Date(now.getTime() + duration * 86400000);
      default:
        throw new Error('Invalid TTL unit');
    }
  } else {
    throw new Error('Invalid TTL format');
  }

  return timeStamps.getTime();
}
