import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Button,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import { useNavigate } from 'react-router';

import { useTranslateTextMutation } from '../../../features/translateApiSlice';

import { useGetContentsByTagPartnerQuery } from '../../../features/addons/contentPagesApiSlice';

const Partner = () => {
  const [content, setContent] = useState('');

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [activeChecked, setActiveChecked] = useState(true);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [editing, setEditing] = useState(false);

  const history = useNavigate();

  const [translateText] = useTranslateTextMutation();

  const {
    data: fetchContentPages,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetContentsByTagPartnerQuery({ skip: false }); // Set skip to false to force a fetch every time

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        setData(fetchContentPages.data);
      }
    };
    fn();
  }, [fetchContentPages]);

  const onAddContents = (id) => {
    //console.log(id);
    history(`/admin/addons/content-pages/add/${id}`);
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: (row) => `${row.id}`,
      omit: true,
    },
    {
      name: 'Title',
      selector: (row) => row['title'],
      sortable: true,
      center: false,
    },
    {
      name: 'Slug',
      selector: (row) => `${row.slug}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Tag',
      selector: (row) => `${row.tag}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Active',
      selector: (row) => `${row.active}`,
      sortable: true,
      center: true,
      style: { width: '80' },
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Button
          onClick={() => onAddContents(row.id)}
          className="btn btn-primary btn-sm px-1"
          style={{ whiteSpace: 'nowrap' }}
          type="button"
        >
          {'Edit Contents'}
        </Button>
      ),
      button: true, // This property ensures proper rendering
      //   conditionalCellStyles: [
      //     {
      //       when: 'max-width: 767px', // Apply styles for smaller screens
      //       style: {
      //         width: '100%', // Adjust column width for smaller screens
      //         whiteSpace: 'unset', // Prevent text wrapping
      //       },
      //     },
      //   ],
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Content Pages"
        parent="Addons"
        title="Content Pages"
        subParent=""
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Col md="6">
                  <H5>Partner</H5>
                </Col>
              </CardHeader>

              <CardBody>
                <DataTable
                  columns={tableColumns}
                  data={data}
                  striped={true}
                  center={true}
                  pagination
                  // selectableRows
                  // selectableRowsSingle
                  // selectableRowsHighlight
                  //onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleSelect}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Partner;
