import NewDashboard from "../Components/Dashboard/NewDashboard";

// //Web
import Customers from "../Components/Web/Customers";
import Orders from "../Components/Web/Orders";
import Inquiries from "../Components/Web/Inquiries";
import Quiz from "../Components/Web/Quiz";

// //Addons
import RiiseProducts from "../Components/AddOns/Products/RiiseProducts";
import Ingredients from "../Components/AddOns/Ingredients/Ingredients";
import AddStock from "../Components/AddOns/Ingredients/AddStock";
import ContentPages from "../Components/AddOns/Contents";
import AboutUs from "../Components/AddOns/Contents/AboutUs";
import HomePage from "../Components/AddOns/Contents/HomePage";
import Partner from "../Components/AddOns/Contents/Partner";
import HeaderImage from "../Components/AddOns/Contents/HeaderImages";

import AddContents from "../Components/AddOns/Contents/AddContents";
import Blogs from "../Components/AddOns/Blogs";
import AddBlogs from "../Components/AddOns/Blogs/AddBlog";
import Faqs from "../Components/AddOns/Faqs";
import DiscountCode from "../Components/AddOns/DiscountCodes";
import Testimonials from "../Components/AddOns/Contents/Testimonials";
import AddTestimonialRemarks from "../Components/AddOns/Contents/AddTestimonialRemarks";
import Subscriptions from "../Components/AddOns/Subscriptions";
import WhyRiise from "../Components/AddOns/WhyRiise";

// //User Management
import Users from "../Components/UserManagement/Users";
import UserProfile from "../Components/UserManagement/UserProfile";

//Website Settings
import BasicSettings from "../Components/SiteSettings/BasicSettings";
import ContactSettings from "../Components/SiteSettings/ContactSettings";
import SEOSettings from "../Components/SiteSettings/SEOSettings";
import EmbededScriptSettings from "../Components/SiteSettings/EmbededScriptSettings";
import PaymentGateways from "../Components/SiteSettings/PaymentGateways";
import StripeCustomers from "../Components/Stripe/Customers";
import StripeSubscriptions from "../Components/Stripe/Subscriptions";
import StripePayments from "../Components/Stripe/Payments";

export const routes = [
  { path: `/admin/dashboard`, Component: <NewDashboard /> },
  { path: `/admin/customers`, Component: <Customers /> },
  { path: `/admin/orders`, Component: <Orders /> },
  {
    path: `/admin/inquiries`,
    Component: <Inquiries />,
  },
  { path: `/admin/quiz`, Component: <Quiz /> },

  {
    path: `/admin/products`,
    Component: <RiiseProducts />,
  },
  {
    path: `/admin/products/ingredients`,
    Component: <Ingredients />,
  },
  {
    path: `/admin/products/add-stock`,
    Component: <AddStock />,
  },
  {
    path: `/admin/products/discount-codes`,
    Component: <DiscountCode />,
  },

  {
    path: `/admin/addons/content-pages`,
    Component: <ContentPages />,
  },
  {
    path: `/admin/addons/about-us`,
    Component: <AboutUs />,
  },
  {
    path: `/admin/addons/home-page`,
    Component: <HomePage />,
  },
  {
    path: `/admin/addons/partner`,
    Component: <Partner />,
  },
  {
    path: `/admin/addons/headerimage`,
    Component: <HeaderImage />,
  },

  {
    path: `/admin/addons/content-pages/add/:id`,
    Component: <AddContents />,
  },
  {
    path: `/admin/addons/blogs`,
    Component: <Blogs />,
  },
  {
    path: `/admin/addons/blogs/add/:id`,
    Component: <AddBlogs />,
  },
  {
    path: `/admin/addons/faqs`,
    Component: <Faqs />,
  },
  {
    path: `/admin/addons/testimonials`,
    Component: <Testimonials />,
  },
  {
    path: `/admin/addons/testimonials/add/:id`,
    Component: <AddTestimonialRemarks />,
  },
  { path: `/admin/addons/subscriptions`, Component: <Subscriptions /> },
  { path: `/admin/addons/whyriise`, Component: <WhyRiise /> },

  {
    path: `/admin/user-management/users`,
    Component: <Users />,
  },
  {
    path: `/admin/user-management/profile`,
    Component: <UserProfile />,
  },

  {
    path: `/admin/site-settings/basic`,
    Component: <BasicSettings />,
  },
  {
    path: `/admin/site-settings/contact`,
    Component: <ContactSettings />,
  },
  {
    path: `/admin/site-settings/seo`,
    Component: <SEOSettings />,
  },
  {
    path: `/admin/site-settings/embed-script`,
    Component: <EmbededScriptSettings />,
  },
  {
    path: `/admin/site-settings/payment-gateways`,
    Component: <PaymentGateways />,
  },
  {
    path: `/admin/stripe/customers`,
    Component: <StripeCustomers />,
  },
  {
    path: `/admin/stripe/subscriptions`,
    Component: <StripeSubscriptions />,
  },
  {
    path: `/admin/stripe/payments`,
    Component: <StripePayments />,
  },

  // { path: `/admin/dashboard`, Component: <NewDashboard /> },
  //
  // { path: `/admin/customers`, Component: <Customers /> },
  // { path: `/admin/inquiries`, Component: <Inquiries /> },
  //
  // { path: `/admin/products`, Component: <RiiseProducts /> },
  // { path: `/admin/products/ingredients`, Component: <Ingredients /> },
  // { path: `/admin/products/add-stock`, Component: <AddStock /> },
  // { path: `/admin/products/discount-codes`, Component: <DiscountCode /> },
  //
  // { path: `/admin/addons/content-pages`, Component: <ContentPages /> },
  // { path: `/admin/addons/aboutus`, Component: <AboutUs /> },
  // { path: `/admin/addons/home-page`, Component: <HomePage /> },
  //
  // { path: `/admin/addons/content-pages/add/:id`, Component: <AddContents /> },
  // { path: `/admin/addons/blogs`, Component: <Blogs /> },
  // { path: `/admin/addons/blogs/add/:id`, Component: <AddBlogs /> },
  // { path: `/admin/addons/faqs`, Component: <Faqs /> },
  // { path: `/admin/addons/testimonials`, Component: <Testimonials /> },
  // { path: `/admin/addons/testimonials/add/:id`, Component: <AddTestimonialRemarks /> },
  //
  // { path: `/admin/user-management/users`, Component: <Users /> },
  // { path: `/admin/user-management/profile`, Component: <UserProfile /> },
  //
  // { path: `/admin/site-settings/basic`, Component: <BasicSettings /> },
  // { path: `/admin/site-settings/contact`, Component: <ContactSettings /> },
  // { path: `/admin/site-settings/seo`, Component: <SEOSettings /> },
  // { path: `/admin/site-settings/embed-script`, Component: <EmbededScriptSettings /> },
  // { path: `/admin/site-settings/payment-gateways`, Component: <PaymentGateways /> },
];
