import { apiSlice } from "../../app/api/apiSlice";

export const discountCodesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDiscountCodes: builder.query({
            query: () => 'discountcode/all',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        addDiscountCode: builder.mutation({
            query: payload => ({
                url: 'discountcode',
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateDiscountCode: builder.mutation({
            query: payload => ({
                url: `discountcode`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
    })
})

export const {
    useGetDiscountCodesQuery,
    useAddDiscountCodeMutation,
    useUpdateDiscountCodeMutation
} = discountCodesApiSlice