import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  Container,
  Input,
  Row,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

import { useForm, Controller } from 'react-hook-form';

import {
  useGetDiscountCodesQuery,
  useAddDiscountCodeMutation,
  useUpdateDiscountCodeMutation,
} from '../../../features/addons/discountCodesApiSlice';

const DiscountCodes = () => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [activeChecked, setActiveChecked] = useState(true);
  const [cumulableChecked, setCumulableChecked] = useState(false);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [editing, setEditing] = useState(false);

  const [addDiscountCode] = useAddDiscountCodeMutation();
  const [updateDiscountCode] = useUpdateDiscountCodeMutation();
  const [addLoading, setAddLoading] = useState(false);

  const {
    data: fetchCodes,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetDiscountCodesQuery({ skip: false }); // Set skip to false to force a fetch every time
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        setData(fetchCodes.data);
      }
    };
    fn();
  }, [fetchCodes]);

  const {
    register,
    reset,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      optDiscountType: '',
      optApplicableTo: '',
      code: '',
      fromDate: '',
      toDate: '',
      discount: '',
      activeCheck: 'checked',
      cumulableCheck: '',
    },
  });

  const onSubmit = async (values) => {
    if (values !== '') {
      if (validate(values)) {
        const formData = {
          code: values.code,
          discount_type: getValues('optDiscountType'),
          discount_value: values.discount,
          applicable_to: getValues('optApplicableTo'),
          cumulable: cumulableChecked ? 1 : 0,
          valid_from: getValues('fromDate'),
          valid_to: getValues('toDate'),
          active: activeChecked ? 1 : 0,
        };

        try {
          setAddLoading(true)
          if (!editing) {
            // new
            const res = await addDiscountCode(formData).unwrap();
            let markers = [...data];
            markers.unshift(res.data);
            setData(markers);

            clears();

            toast.success('Discount code saved.');
          } else {
            // edit
            formData.id = selectedRow[0].id;
            formData.stripePromoId = selectedRow[0]?.stripePromoId;
            const res = await updateDiscountCode(formData).unwrap();
            let markers = [...data];
            let index = markers.findIndex((el) => el.id === formData.id);

            markers[index] = res.data;

            setData(markers);

            clears();

            toast.success('Discount code updated.');
          }
        } catch (err) {
          if (err.status === 400) {
            toast.error(err.data?.message);
          } else {
            toast.error('Discount code not saved.');
          }
        }
        finally{
          setAddLoading(false)
        }
      }
    } else {
      toast.error('Discount code not saved.');
    }
  };

  const validate = (values) => {
    let index;
    if (!editing) {
      index = data.findIndex(
        (el) => el.code.toLowerCase() === values.code.toLowerCase()
      );
    } else {
      index = data.findIndex(
        (el) =>
          el.code.toLowerCase() === values.code.toLowerCase() &&
          editing &&
          el.id !== selectedRow[0].id
      );
    }

    if (index > -1) {
      setError('code', {
        type: 'manual',
        message: 'Discount code already taken.',
      });
      return false;
    }

    if(!/^[a-zA-Z0-9]+$/.test(getValues('code'))){
      setError('code', {
        type: 'manual',
        message: 'Only alphabets and numbers are allowed.',
      });
      return false;
    }

    const discount = parseFloat(getValues('discount'));
    if (isNaN(discount) || discount < 1) {
      setError('discount', {
        type: 'manual',
        message: 'Please enter valid discount.',
      });
      return false;
    }

    if (getValues('fromDate') === '') {
      setError('fromDate', {
        type: 'manual',
        message: 'Please enter valid from date.',
      });
      return false;
    }

    if (getValues('toDate') === '') {
      setError('toDate', {
        type: 'manual',
        message: 'Please enter valid to date.',
      });
      return false;
    }

    return true;
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRow(state.selectedRows);
    if (state.selectedCount === 0) {
      clears();
    }
  }, []);

  const clears = () => {
    setEditing(false);
    reset();

    // setValue('optDiscountType', '')
    // setValue('optApplicableTo', '')
    // setValue('code', '')
    // setValue('discount', '')
    // setValue('fromDate', '')
    // setValue('toDate', '')
    // setActiveChecked(true)
    // setCumulableChecked(false)
  };

  const handleEdit = () => {
    setEditing(true);

    setValue('optDiscountType', selectedRow[0].discount_type);
    setValue('optApplicableTo', selectedRow[0].applicable_to);
    setValue('code', selectedRow[0].code);
    setValue('discount', selectedRow[0].discount_value);

    let date = moment(selectedRow[0].valid_from, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    );
    setValue('fromDate', date);

    date = moment(selectedRow[0].valid_to, 'DD/MM/YYYY').format('YYYY-MM-DD');
    setValue('toDate', date);

    setCumulableChecked(selectedRow[0].cumulable === 'Yes');
    setActiveChecked(selectedRow[0].active === 'Yes');
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: (row) => `${row.id}`,
      omit: true,
    },
    {
      name: 'Code',
      selector: (row) => row['code'],
      sortable: true,
      center: false,
    },
    {
      name: 'Discount',
      selector: (row) => `${row.discount_value}`,
      sortable: true,
      center: false,
    },
    {
      name: 'Discount Type',
      selector: (row) => row['discount_type'],
      sortable: true,
      center: false,
    },
    {
      name: 'Applicable To',
      selector: (row) => `${row.applicable_to}`,
      sortable: true,
      center: true,
    },
    {
      name: 'From',
      selector: (row) => `${row.valid_from}`,
      sortable: true,
      center: true,
    },
    {
      name: 'To',
      selector: (row) => `${row.valid_to}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Cumulable',
      selector: (row) => `${row.cumulable}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Active',
      selector: (row) => `${row.active}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Stripe Id',
      selector: (row) => `${row?.stripePromoId}`,
      sortable: false,
      center: true,
    },
  ];

  const DiscountTypes = [
    'First Purchase',
    'First Purchase & Renewals',
    'Fake Discount',
  ];
  const ApplicableTos = [
    'All Purchases',
    'Monthly Subscription',
    '3-Month Subscription',
    'Subscriptions Only',
  ];

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle='Discount Codes'
        parent='Products'
        title='Discount Codes'
        subParent=''
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <H5>Discount Codes</H5>
              </CardHeader>
              <CardBody>
                <Form
                  className='needs-validation'
                  noValidate=''
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md='6 mb-3'>
                      <Label className='form-label' for='optDiscountType'>
                        Discount Type
                      </Label>
                      <Controller
                        name='optDiscountType'
                        id='optDiscountType'
                        control={control}
                        rules={{ required: true }} // Add a validation rule for required selection
                        render={({ field }) => (
                          <select
                            {...field}
                            className='form-select form-control'
                          >
                            <option value=''>
                              {'--Select Discount Type--'}
                            </option>
                            {DiscountTypes.map((type, index) => (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                    </Col>
                    <Col md='6 mb-3'>
                      <Label className='form-label' for='optApplicableTo'>
                        Applicable To
                      </Label>
                      <Controller
                        name='optApplicableTo'
                        id='optApplicableTo'
                        control={control}
                        rules={{ required: true }} // Add a validation rule for required selection
                        render={({ field }) => (
                          <select
                            {...field}
                            className='form-select form-control'
                          >
                            <option value=''>
                              {'--Select Applicable To--'}
                            </option>
                            {ApplicableTos.map((val, index) => (
                              <option key={index} value={val}>
                                {val}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md='6 mb-3'>
                      <Label className='form-label' for='code'>
                        Code
                      </Label>
                      <input
                        className='form-control'
                        id='code'
                        type='text'
                        placeholder='Code'
                        name='code'
                        {...register('code', { required: true })}
                      />
                      <span className='d-block txt-danger'>
                        {errors.code &&
                          (errors.code.message
                            ? errors.code.message
                            : 'Code is required')}
                      </span>
                    </Col>
                    <Col md='6 mb-3'>
                      <Label className='form-label' for='discount'>
                        Discount Value (%)
                      </Label>
                      <input
                        className='form-control'
                        id='discount'
                        type='text'
                        placeholder='Discount Value'
                        name='discount'
                        {...register('discount', {
                          required: true,
                          min: 1,
                          max: 100,
                        })}
                      />
                      <span className='d-block txt-danger'>
                        {errors.discount && 'Please enter discount value.'}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md='6 mb-3'>
                      <Label className='form-label' for='fromDate'>
                        Valid From
                      </Label>
                      <input
                        className='form-control digits'
                        id='fromDate'
                        name='fromDate'
                        type='date'
                        {...register('fromDate', { required: false })}
                      />
                      <span className='d-block txt-danger'>
                        {errors.fromDate && 'Please enter valid from date.'}
                      </span>
                    </Col>
                    <Col md='6 mb-3'>
                      <Label className='form-label' for='toDate'>
                        Valid To
                      </Label>
                      <input
                        className='form-control digits'
                        id='toDate'
                        type='date'
                        name='toDate'
                        {...register('toDate', { required: false })}
                      />
                      <span className='d-block txt-danger'>
                        {errors.toDate && 'Please enter valid to date.'}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md='6 mb-3'>
                      <div className='checkbox p-0'>
                        <Input
                          className='form-check-input'
                          id='cumulableCheck'
                          type='checkbox'
                          checked={cumulableChecked}
                          onClick={() => {
                            setCumulableChecked(!cumulableChecked);
                          }}
                          {...register('cumulableCheck')}
                        />
                        <Label
                          className='form-check-label'
                          htmlFor='cumulableCheck'
                        >
                          {'Cumulable'}
                        </Label>
                      </div>
                    </Col>

                    <Col md='6 mb-3'>
                      <div className='checkbox p-0'>
                        <Input
                          className='form-check-input'
                          id='activeCheck'
                          type='checkbox'
                          checked={activeChecked}
                          onClick={() => {
                            setActiveChecked(!activeChecked);
                          }}
                          {...register('activeCheck')}
                        />
                        <Label
                          className='form-check-label'
                          htmlFor='activeCheck'
                        >
                          {'Active'}
                        </Label>
                      </div>
                    </Col>
                  </Row>

                  <Btn type='submit' attrBtn={{ color: 'primary' }}>
                    {editing ? 'Update' : 'Save'}
                  </Btn>
                </Form>
              </CardBody>
              <CardBody>
                {selectedRow.length !== 0 && (
                  <>
                    <div className={'float-end w-100 d-flex bg-light-info'}>
                      <div
                        className={`d-flex align-items-center justify-content-between p-2`}
                      >
                        <Btn
                          attrBtn={{
                            color: 'primary',
                            onClick: () => handleEdit(),
                          }}
                        >
                          Update
                        </Btn>
                      </div>
                    </div>
                  </>
                )}
                <DataTable
                  columns={tableColumns}
                  data={data}
                  striped={true}
                  center={true}
                  pagination
                  selectableRows
                  selectableRowsSingle
                  selectableRowsHighlight
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleSelect}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DiscountCodes;
