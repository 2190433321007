import { apiSlice } from "../../app/api/apiSlice";


export const BlogsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBlogList: builder.query({
            query: () => 'blog/list',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        getBlog: builder.query({
            query: (payload) => ({
                url: 'blogbyid',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        addBlog: builder.mutation({
            query: payload => ({
                url: 'blog',
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateBlog: builder.mutation({
            query: payload => ({
                url: `blog`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
        
        })
    })

export const {
    useGetBlogListQuery,
    useGetBlogQuery,
    useAddBlogMutation,
    useUpdateBlogMutation,
} = BlogsApiSlice