const convertJSONToCSV = (jsonData, columnHeaders) => {
  if (jsonData.length === 0) {
    return "";
  }
  const headers = columnHeaders.join(",") + "\n";
  const rows = jsonData
    .map((row) => {
      return columnHeaders.map((field) => row[field] || "").join(",");
    })
    .join("\n");
  return headers + rows;
};

export const downloadCsv = (jsonData, headers) => {
  const csvData = convertJSONToCSV(jsonData, headers);

  if (csvData === "") {
    return;
  } else {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "Ingredients.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
