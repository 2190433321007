import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Breadcrumbs, Btn, H5 } from "../../../AbstractElements";
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    Form,
    Label,
    Container,
    Row,
    Button,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import {useTranslateTextMutation} from "../../../features/translateApiSlice";
import {useUpdateSubscriptionMutation} from "../../../features/addons/subscriptionsApiSlice";
import {useGetSubscriptionListQuery} from "../../../features/addons/subscriptionsApiSlice";
import {useForm} from "react-hook-form";

const Subscriptions = () => {

    const [translateText] = useTranslateTextMutation();
    const [updateSubscription] = useUpdateSubscriptionMutation();

    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [activeChecked, setActiveChecked] = useState(true);
    const [toggleSelect, setToggleSelect] = useState(false);
    const [pictureUpload, setPictureUpload] = useState(null);
    const [pictureSrc, setPictureSrc] = useState();


    const {
        data: fetchSub,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch,
    } = useGetSubscriptionListQuery({ skip: false })


    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(()=>{

        if(isSuccess) {
            console.log(fetchSub)
            setData(fetchSub.subscriptions)
        }

    },[fetchSub])
    const onTranslateTitle = async (e) => {
        e.preventDefault();
        const text = getValues('title')

        console.log(text);

        if (text !== "") {
            try {

                setValue('title_de', '')

                const response = await translateText({ contents: text }).unwrap();
                console.log(response.data);
                setValue('title_de', response.data)
            } catch (err) {
                console.log(err);
            }
        }
    };
    const onTranslateDescription = async (e) => {
        e.preventDefault();
        const text = getValues('description')

        console.log(text);

        if (text !== "") {
            try {

                setValue('description_de', '')

                const response = await translateText({ contents: text }).unwrap();
                console.log(response.data);
                setValue('description_de', response.data)
            } catch (err) {
                console.log(err);
                //toast.error(err);
            }
        }
    };

    const onSubmit = async (values) => {

        if (values !== '') {

            const formData = {
                id: selectedRow.id,
                title: values.title,
                title_de: values.title_de,
                description: values.description,
                description_de: values.description_de,
                duration: values.duration
            }


            await updateSubscription(formData)
                .then(async (res) => {
                    console.log(res.data);

                    let markers = [...data];
                    let index = markers.findIndex(el => el.id === formData.id);

                    markers[index] = formData;

                    setData(markers)

                    setSelectedRow(null)
                    reset();

                    toast.success("Subscription saved");
                })
                .catch((err) => {
                    console.log(err);

                    toast.error("Subscription not saved.");
                });
        }
    };

    const onEditContents = (id) => {
        //console.log(id);

        let index = data.findIndex(el => el.id === id);

        let f = data[index];
        setSelectedRow(f)

        setValue('title', f.title)
        setValue('title_de', f.title_de)
        setValue('description', f.description)
        setValue('description_de', f.description_de)
        setValue('duration', f.duration)


        if(f.picture && f.picture !== '') {
            const src_pic = `${process.env.REACT_APP_BASE_URL}/${f.picture}`;

            setPictureSrc(src_pic);
        }
        else {
            setPictureSrc('');
        }
    };

    const {
        register,
        setValue,
        getValues,
        control,
        setError,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            title: '',
            title_de: '',
            description: '',
            description_de: '',
            duration: ''
        }
    });

    const clears = () => {

        setActiveChecked(true);
    };

    const handleRowSelected = useCallback((state) => {
        //console.log(state)
        setSelectedRow(state.selectedRows);
        if (state.selectedCount === 0) {
            clears();
        }
    }, []);


    const onPictureChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Read the selected file and set the image source
            const reader = new FileReader();
            reader.onload = (e) => {
                setPictureSrc(`${e.target.result}`);
            };
            reader.readAsDataURL(file);

            setPictureUpload(file);
        }

    };

    const onUploadPicture = (e) => {
        e.preventDefault();

        if (pictureUpload) {
            console.log(pictureUpload);
            const formData = new FormData();
            formData.append('id', selectedRow.id);
            formData.append('logo', pictureUpload, pictureUpload.name);
            formData.append('fileName', 'picture');
            formData.append('table', 'subscriptions');
            formData.append('column', 'picture');

            callUploadFile(formData);

            return true;
        }
    };

    const callUploadFile = (formData) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/upload`, {
            method: 'PUT',
            body: formData,
        })
            .then((res) => {
                if (!res.ok) {
                    return Promise.reject(res);
                }
                return res.json();
            })
            .then((data) => {
                console.log(data);
                toast.success(data.message);
            })
            .catch((err) => {
                console.error(err);
                toast.error(err);
            });
    };


    const tableColumns = [
        {
            name: "Id",
            selector: (row) => `${row.id}`,
            omit: true,
        },
        {
            name: "Title",
            selector: (row) => row["title"],
            sortable: true,
            center: false,
        },
        {
            name: "Description",
            selector: (row) => `${row.description}`,
            sortable: true,
            center: false,
        },
        {
            name: "Duration",
            selector: (row) => `${row.duration}`,
            sortable: true,
            center: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <Button
                    onClick={() => onEditContents(row.id)}
                    className="btn btn-primary btn-sm px-1 px-3"
                    type="button"
                >
                    {"Edit"}
                </Button>
            ),
            button: true,
        },
    ];


    return (
        <Fragment>
            <Breadcrumbs
                mainTitle="Subscriptions List"
                parent="Add Ons"
                title="Subscriptions"
                subParent=""
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <H5>Subscriptions</H5>

                            </CardHeader>
                            <CardBody>
                                <Form
                                    className="needs-validation"
                                    noValidate=""
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Row>
                                        <Col md="6 mb-3">
                                            <Label className="form-label" for="name">
                                                Title(English)
                                            </Label>
                                            <input
                                                className="form-control"
                                                id="title"
                                                type="text"
                                                placeholder="title"
                                                name="title"
                                                required
                                                minLength={3}
                                                {...register('title', { required: true })}
                                            />
                                            {/* <span className='d-block'>{errors.productName && 'Ingredient name is required'}</span> */}
                                        </Col>
                                        <Col md="6 mb-3">
                                            <div>
                                                <Label className="form-label" for="name_de">
                                                    Title(German)
                                                </Label>
                                                &nbsp;
                                                <Button
                                                    type="button"
                                                    className="btn py-0 px-2"
                                                    onClick={onTranslateTitle}
                                                >
                                                    <i className="fa fa-language"></i>
                                                </Button>
                                            </div>

                                            <input
                                                className="form-control"
                                                id="title_de"
                                                type="text"
                                                name="title_de"
                                                {...register('title_de', { required: true })}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6 mb-3">
                                            <Label className="form-label" for="description">
                                                Description(English)
                                            </Label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                rows={5}
                                                placeholder="Description"
                                                name="description"
                                                required
                                                minLength={5}
                                                {...register('description', { required: true })}
                                            ></textarea>
                                        </Col>
                                        <Col md="6 mb-3">
                                            <div>
                                                <Label className="form-label" for="description_de">
                                                    Description(German)
                                                </Label>
                                                &nbsp;
                                                <Button
                                                    type="button"
                                                    className="btn py-0 px-2"
                                                    onClick={onTranslateDescription}
                                                >
                                                    <i className="fa fa-language"></i>
                                                </Button>
                                            </div>
                                            <textarea
                                                className="form-control"
                                                id="description_de"
                                                rows={5}
                                                placeholder="Description"
                                                name="description_de"
                                                {...register('description_de', { required: true })}
                                            ></textarea>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6 mb-3">
                                            <Label className="form-label" for="name">
                                                Duration
                                            </Label>
                                            <input
                                                className="form-control"
                                                id="duration"
                                                type="text"
                                                placeholder="Duration"
                                                name="duration"
                                                required
                                                minLength={1}
                                                {...register('duration', { required: true })}
                                            />
                                            {/* <span className='d-block'>{errors.productName && 'Ingredient name is required'}</span> */}

                                            <div className="mt-3">
                                                <Btn type="submit" attrBtn={"save"} on>
                                                    Update Subscription
                                                </Btn>
                                            </div>

                                        </Col>
                                        <Col md="6 mb-3 p-4">
                                            <Label className="form-label" for="picture">Picture</Label>
                                            {pictureSrc ? (
                                                <div>
                                                    <img src={pictureSrc} alt="Selected" width="70" />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{ pictureSrc }}
                                                ></div>
                                            )}

                                            <input
                                                className="form-control" type="file"
                                                name="picture" accept="image/*"
                                                onChange={(e) => onPictureChange(e)}
                                            />
                                            <Button
                                                style={{ color: 'primary' }}
                                                onClick={(e) => onUploadPicture(e)}
                                            >
                                                {'Upload'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>

                            <CardBody>
                                <DataTable
                                    columns={tableColumns}
                                    data={data}
                                    striped={true}
                                    center={true}
                                    pagination
                                    onSelectedRowsChange={handleRowSelected}
                                    clearSelectedRows={toggleSelect}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Fragment>
    );
}

export default Subscriptions;