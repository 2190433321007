import { apiSlice } from "../../app/api/apiSlice";


export const FaqsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFaqsList: builder.query({
            query: () => 'faqs/list',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        getFaqs: builder.query({
            query: (payload) => ({
                url: 'faqsbyid',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
        addFaqs: builder.mutation({
            query: payload => ({
                url: 'faqs',
                method: 'POST',
                body: { ...payload }
            })
        }),
        updateFaqs: builder.mutation({
            query: payload => ({
                url: `faqs`,
                method: 'PUT',
                body: { ...payload }
            })
        }),
        
        })
    })

export const {
    useGetFaqsListQuery,
    useGetFaqsQuery,
    useAddFaqsMutation,
    useUpdateFaqsMutation,
} = FaqsApiSlice