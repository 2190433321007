import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Breadcrumbs, Btn, H5, Image } from '../../../AbstractElements';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  Container,
  Input,
  Row,
  Button,
  NavLink,
} from 'reactstrap';
import CKEditors from 'react-ckeditor-component';
import { ToastContainer, toast } from 'react-toastify';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useTranslateTextMutation } from '../../../features/translateApiSlice';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import {
  useGetPageContentQuery,
  useUpdateContentPageMutation,
} from '../../../features/addons/contentPagesApiSlice';

const AddContents = () => {
  const id = window.location.pathname.split('/').pop();

  const [content, setContent] = useState('');
  const [contentDe, setContentDe] = useState('');

  const [pictureUpload, setPictureUpload] = useState(null);
  const [pictureSrc, setPictureSrc] = useState();

  const [activeChecked, setActiveChecked] = useState(false);

  const [skip, setSkip] = useState(false);

  const [updateContentPage] = useUpdateContentPageMutation();
  const [translateText] = useTranslateTextMutation();

  const [contentId, setContentId] = useState(id);

  const history = useNavigate();

  const {
    data: fetchContents,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetPageContentQuery({ id: contentId, skip: false }); // Set skip to false to force a fetch every time

  useEffect(() => {
    //console.log(contentId)

    refetch();
  }, [contentId]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        console.log(fetchContents.data);
        setValue('title', fetchContents.data.title);
        setValue('title_de', fetchContents.data.title_de);
        setValue('slug', fetchContents.data.slug);
        setValue('tag', fetchContents.data.tag);

        setContent(fetchContents.data.contents);
        setContentDe(fetchContents.data.contents_de);
        setActiveChecked(fetchContents.data.active === 'Yes');

        const src_pic = `${process.env.REACT_APP_BASE_URL}/${fetchContents.data.picture}`;

        setPictureSrc(src_pic);
      }
    };
    fn();
  }, [fetchContents, skip]);

  const {
    register,
    setValue,
    getValues,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      title_de: '',
      slug: '',
      tag: '',
    },
  });

  const onSubmit = async (values) => {
    if (values !== '') {
      if (validate(values)) {
        const formData = {
          id: contentId,
          title: values.title,
          title_de: values.title_de,
          contents: content,
          contents_de: contentDe,
          slug: values.slug,
          tag: values.tag,
          picture: '',
          active: activeChecked ? 1 : 0,
        };

        console.log(formData);

        try {
          const res = await updateContentPage(formData).unwrap();
          console.log(res.data);

          toast.success('Data updated.');
        } catch (err) {
          if (err.status === 400) {
            toast.error(err.data?.message);
          } else {
            toast.error('Data not saved.');
          }
        }
      }
    } else {
      toast.error('Data not saved.');
    }
  };

  const validate = (values) => {
    return true;
  };

  const onContentChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  const onContentChangeDe = (evt) => {
    const newContent = evt.editor.getData();
    setContentDe(newContent);
  };

  const onTranslate = async (e, element) => {
    e.preventDefault();

    let text;

    if (element === 'title') text = getValues('title');
    else text = content;

    console.log(text);

    if (text !== '') {
      try {
        if (element === 'title') setValue('title_de', '');
        else setContentDe('');

        const response = await translateText({ contents: text }).unwrap();
        console.log(response.data);

        if (element === 'title') setValue('title_de', response.data);
        else setContentDe(response.data);
      } catch (err) {
        console.log(err);
        //toast.error(err);
      }
    }
  };

  const onPictureChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Read the selected file and set the image source
      const reader = new FileReader();
      reader.onload = (e) => {
        setPictureSrc(`${e.target.result}`);
      };
      reader.readAsDataURL(file);

      setPictureUpload(file);
      //setSelectedImage(file); // Store the selected file in state
    }

    //setPictureSrc(e.target.files[0])
  };

  const onUploadPicture = (e) => {
    e.preventDefault();

    if (pictureUpload) {
      console.log(pictureUpload);
      const formData = new FormData();
      formData.append('id', contentId);
      formData.append('logo', pictureUpload, pictureUpload.name);
      formData.append('fileName', 'picture');
      formData.append('table', 'content_pages');
      formData.append('column', 'picture');

      callUploadFile(formData);

      return true;
    }
  };

  const callUploadFile = (formData) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/upload`, {
      method: 'PUT',
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        toast.success(data.message);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err);
      });
  };

  const onContentPagesClick = (e) => {
    e.preventDefault();
    history(`/admin/addons/content-pages`);
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Content Pages"
        parent="Addons"
        subParent="Content Pages"
        title="Add Contents"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <H5>Add Contents</H5>
                  </Col>
                  <Col md="6" style={{ textAlign: 'right' }}>
                    {/*<NavLink href='../' style={{whiteSpace: 'nowrap', color: '#7366ff'}} onClick={e => onContentPagesClick(e)}>Content Pages</NavLink>*/}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="title">
                        Title (English)
                      </Label>
                      <input
                        className="form-control"
                        id="title"
                        type="text"
                        placeholder="Title"
                        name="title"
                        {...register('title', { required: true })}
                      />
                      <span className="d-block">
                        {errors.title && 'Title is required'}
                      </span>

                      <div className="mt-3">
                        <Label className="form-label" for="title_de">
                          Title (German)
                        </Label>
                        &nbsp;
                        <Button
                          type="button"
                          className="btn py-0 px-2"
                          onClick={(e) => onTranslate(e, 'title')}
                        >
                          <i className="fa fa-language"></i>
                        </Button>
                      </div>
                      <input
                        className="form-control"
                        id="title_de"
                        type="text"
                        placeholder="Title"
                        name="title_de"
                        {...register('title_de')}
                      />

                      <div className="mt-3">
                        <Label className="form-label" for="slug">
                          Slug
                        </Label>
                        <input
                          className="form-control"
                          id="slug"
                          type="text"
                          placeholder="Slug"
                          name="slug"
                          {...register('slug', { required: true })}
                        />
                        <span className="d-block">
                          {errors.slug && 'Slug is required'}
                        </span>
                      </div>

                      <div className="mt-3">
                        <Label className="form-label" for="tag">
                          Tag
                        </Label>
                        <input
                          className="form-control"
                          id="tag"
                          type="text"
                          placeholder="Tag"
                          name="tag"
                          {...register('tag')}
                        />
                      </div>

                      <div className="mt-3">
                        <div className="checkbox p-0">
                          <Input
                            className="form-check-input"
                            id="activeCheck"
                            type="checkbox"
                            checked={activeChecked}
                            onClick={() => {
                              setActiveChecked(!activeChecked);
                            }}
                            {...register('activeCheck')}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="activeCheck"
                          >
                            {'Active'}
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="picture">
                        Picture
                      </Label>
                      {pictureSrc ? (
                        <div>
                          <img src={pictureSrc} alt="Selected" width="300" />
                        </div>
                      ) : (
                        <div
                          className="background-image"
                          style={{ pictureSrc }}
                        ></div>
                      )}

                      <input
                        className="form-control"
                        type="file"
                        name="picture"
                        accept="image/*"
                        onChange={(e) => onPictureChange(e)}
                      />
                      <Button
                        style={{ color: 'primary' }}
                        onClick={(e) => onUploadPicture(e)}
                      >
                        {'Upload'}
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12 mb-3">
                      <Label className="form-label" for="description">
                        Contents (English)
                      </Label>
                      <CKEditors
                        activeclassName="p10"
                        content={content}
                        events={{
                          change: onContentChange,
                        }}
                      />
                    </Col>
                    <Col md="12 mb-3">
                      <div>
                        <Label className="form-label" for="description">
                          Contents (German)
                        </Label>
                        &nbsp;
                        <Button
                          type="button"
                          className="btn py-0 px-2"
                          onClick={(e) => onTranslate(e, 'content')}
                        >
                          <i className="fa fa-language"></i>
                        </Button>
                      </div>
                      <CKEditors
                        activeclassName="p10"
                        content={contentDe}
                        events={{
                          change: onContentChangeDe,
                        }}
                      />
                    </Col>
                  </Row>

                  <Btn attrBtn={{ color: 'primary' }}>{'Update'}</Btn>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddContents;
