import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../../AbstractElements';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Button,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';

import { useNavigate } from 'react-router';
import { useForm, Controller } from 'react-hook-form';

import { useTranslateTextMutation } from '../../../features/translateApiSlice';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import {
  useGetContentPageListQuery,
  useAddContentPageMutation,
} from '../../../features/addons/contentPagesApiSlice';

const ContentPages = () => {
  const [content, setContent] = useState('');

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [activeChecked, setActiveChecked] = useState(true);
  const [toggleSelect, setToggleSelect] = useState(false);
  const [editing, setEditing] = useState(false);

  const history = useNavigate();

  const [addContentPage] = useAddContentPageMutation();
  const [translateText] = useTranslateTextMutation();

  const {
    data: fetchContentPages,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetContentPageListQuery({ skip: false }); // Set skip to false to force a fetch every time

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fn = () => {
      if (isSuccess) {
        setData(fetchContentPages.data);
      }
    };
    fn();
  }, [fetchContentPages]);

  const {
    register,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      title_de: '',
      slug: '',
      tag: '',
      activeCheck: 'checked',
    },
  });

  const onSubmit = async (values) => {
    if (values !== '') {
      if (validate(values)) {
        const formData = {
          title: values.title,
          title_de: values.title_de,
          contents: '',
          contents_de: '',
          slug: values.slug,
          picture: '',
          tag: values.tag,
          active: activeChecked ? 1 : 0,
        };

        console.log(formData);

        try {
          const res = await addContentPage(formData).unwrap();
          //console.log(res.data)
          let markers = [...data];
          markers.unshift(res.data);
          setData(markers);

          clears();

          toast.success('Data saved.');
        } catch (err) {
          if (err.status === 400) {
            toast.error(err.data?.message);
          } else {
            toast.error('Data not saved.');
          }
        }
      }
    } else {
      toast.error('Data not saved.');
    }
  };

  const validate = (values) => {
    return true;
  };

  const onAddContents = (id) => {
    //console.log(id);
    history(`/admin/addons/content-pages/add/${id}`);
  };

  const handleRowSelected = useCallback((state) => {
    console.log(state);
    setSelectedRow(state.selectedRows);
    if (state.selectedCount === 0) {
      clears();
    }
  }, []);

  const clears = () => {
    setEditing(false);
    setValue('title', '');
    setValue('title_de', '');
    setValue('slug', '');
    setValue('tag', '');
    setActiveChecked(true);
  };

  const onTranslate = async (e) => {
    e.preventDefault();

    let text = getValues('title').trim();

    console.log(text);

    if (text !== '') {
      try {
        setValue('title_de', '');

        const response = await translateText({ contents: text }).unwrap();
        console.log(response.data);

        setValue('title_de', response.data);
      } catch (err) {
        console.log(err);
        //toast.error(err);
      }
    }
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: (row) => `${row.id}`,
      omit: true,
    },
    {
      name: 'Title',
      selector: (row) => row['title'],
      sortable: true,
      center: false,
    },
    {
      name: 'Slug',
      selector: (row) => `${row.slug}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Tag',
      selector: (row) => `${row.tag}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Active',
      selector: (row) => `${row.active}`,
      sortable: true,
      center: true,
      style: { width: '80' },
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Button
          onClick={() => onAddContents(row.id)}
          className="btn btn-primary btn-sm px-1"
          style={{ whiteSpace: 'nowrap' }}
          type="button"
        >
          {'Add Contents'}
        </Button>
      ),
      button: true, // This property ensures proper rendering
      //   conditionalCellStyles: [
      //     {
      //       when: 'max-width: 767px', // Apply styles for smaller screens
      //       style: {
      //         width: '100%', // Adjust column width for smaller screens
      //         whiteSpace: 'unset', // Prevent text wrapping
      //       },
      //     },
      //   ],
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Content Pages"
        parent="Addons"
        title="Content Pages"
        subParent=""
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Col md="6">
                  <H5>Content Pages</H5>
                </Col>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="title">
                        Title (English)
                      </Label>
                      <input
                        className="form-control"
                        id="title"
                        type="text"
                        placeholder="Title"
                        name="title"
                        {...register('title', { required: true })}
                      />
                      <span className="d-block">
                        {errors.title && 'Title is required'}
                      </span>
                    </Col>
                    <Col md="6 mb-3">
                      <div>
                        <Label className="form-label" for="title_de">
                          Title (German)
                        </Label>
                        &nbsp;
                        <Button
                          type="button"
                          className="btn py-0 px-2"
                          onClick={(e) => onTranslate(e)}
                        >
                          <i className="fa fa-language"></i>
                        </Button>
                      </div>
                      <input
                        className="form-control"
                        id="title_de"
                        type="text"
                        placeholder="Title"
                        name="title_de"
                        {...register('title_de')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="slug">
                        Slug
                      </Label>
                      <input
                        className="form-control"
                        id="slug"
                        type="text"
                        placeholder="Slug"
                        name="slug"
                        {...register('slug', { required: true })}
                      />
                      <span className="d-block">
                        {errors.slug && 'Slug is required'}
                      </span>
                    </Col>

                    <Col md="6 mb-3">
                      <Label className="form-label" for="tag">
                        Tag
                      </Label>
                      <input
                        className="form-control"
                        id="tag"
                        type="text"
                        placeholder="Tag"
                        name="tag"
                        {...register('tag')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label className="form-label" for="description">
                        &nbsp;
                      </Label>
                      <div className="checkbox p-0">
                        <Input
                          className="form-check-input"
                          id="activeCheck"
                          type="checkbox"
                          checked={activeChecked}
                          onClick={() => {
                            setActiveChecked(!activeChecked);
                          }}
                          {...register('activeCheck')}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="activeCheck"
                        >
                          {'Active'}
                        </Label>
                      </div>
                    </Col>
                  </Row>

                  <Btn attrBtn={{ color: 'primary' }}>
                    {editing ? 'Update' : 'Save'}
                  </Btn>
                </Form>
              </CardBody>
              <CardBody>
                <DataTable
                  columns={tableColumns}
                  data={data}
                  striped={true}
                  center={true}
                  pagination
                  // selectableRows
                  // selectableRowsSingle
                  // selectableRowsHighlight
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleSelect}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ContentPages;
