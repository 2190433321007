import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumbs, Btn, H5 } from '../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Form, Label, Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";

import { useForm } from 'react-hook-form';
import { Username } from '../../Constant';

import { useGetUserQuery, useUpdateUserMutation } from '../../features/users/userApiSlice';

const UserProfile = () => {

    const [userId, setUserId] = useState([]);
    const [passwordChecked, setPasswordChecked] = useState(false)

    const [updateUser] = useUpdateUserMutation();
    
    const { data: fetchUser, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetUserQuery({email: localStorage.getItem('email'),
                    skip: false, // Set skip to false to force a fetch every time
                })
      
    
    useEffect(() => {
        refetch();
    }, [refetch]);
          
    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                console.log(fetchUser.data)

                setValue('name', fetchUser.data.name)
                setValue('userName', fetchUser.data.email)
                setValue('password', '')
                setUserId(fetchUser.data.id)
            }
        }   
        fn();

    }, [fetchUser]);
    
    

    const {
        register,
        setValue,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm();
      
    
    const onSubmit = async (values) => {
        if (values !== '') {
        
            const formData = {
                name: values.name,
                email: values.userName,
                password: ''
            }

            try {

                if(passwordChecked) {
                    formData.password = values.password
                }

                formData.id = userId
                //console.log(formData)

                const res = await updateUser(formData).unwrap()
                console.log(res.data)

                setValue('password', '')
                
                toast.success("User updated.");
            }
            catch(err) {
                if(err.status === 400) {
                    toast.error(err.data?.message);
                }
                else {
                    toast.error("User not saved.");
                }
            }
            

        } else {
            toast.error("User not saved.");
        }
    };



    return (
        <Fragment>
            <Breadcrumbs mainTitle='User Management' parent="User" title="User Profile" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                    <Card>
                        <CardHeader>
                        <H5>User Profile</H5>
                        </CardHeader>
                        <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                            <Col md='4 mb-3'>
                                <Label className='form-label' for='name'>Name</Label>
                                <input className='form-control' id='name' type='text' name='name' placeholder='Name' {...register('name', { required: true })} />
                                <span className='d-block txt-danger'>{errors.name && 'Name is required'}</span>
                            </Col>
                            <Col md='4 mb-3'>
                                <Label for='userName'>{Username}</Label>
                                <InputGroup>
                                <InputGroupText>{'@'}</InputGroupText>
                                <input className='form-control' id='userName' name='userName' type='text' placeholder='Username' {...register('userName', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })} />
                                <span className='d-block txt-danger w-100'>{errors.userName && 'User name is required'}</span>
                                </InputGroup>
                            </Col>
                            <Col md='4 mb-3'>
                                
                                <div className='form-check'>
                                    <div className='checkbox p-0'>
                                    <Input className='form-check-input' id='passwordCheck' type='checkbox' checked={passwordChecked} onClick={() => {setPasswordChecked(!passwordChecked)}}/>
                                    <Label className='form-check-label mt-0 mb-0' htmlFor='passwordCheck'>
                                        {'Change password'}
                                    </Label>
                                    </div>
                                </div>
                                
                                <input className='form-control' id='password' type='password' name='password' {...register('password', { required: passwordChecked, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/})} />
                                <span className='d-block txt-danger'>
                                    {passwordChecked && errors.password && 'Password should contains a lowercase, a uppercase character and a digit and atleast 8 characters.'}
                                </span>
                            </Col>
                            </Row>
                            
                            <Btn attrBtn={{ color: 'primary'}}>{'Update'}</Btn>
                        </Form>
                        </CardBody>
                    
                    </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Fragment>
    );
};

export default UserProfile;

