export const MENUITEMS = [
  {
    //menutitle: "Main Menu",
    menucontent: "Dashboards,Products,Addons,User Management,Web Sitesettings",
    Items: [
      {
        title: "Dashboard",
        icon: "home",
        type: "sub",
        badge: "badge badge-light-primary",
        badgetxt: "",
        active: false,
        children: [
          {
            path: `/admin/dashboard`,
            title: "Dashboard",
            type: "link",
          },
        ],
      },
      {
        title: "Web",
        icon: "widget",
        type: "sub",
        active: false,
        children: [
          {
            path: `/admin/customers`,
            title: "Customers",
            type: "link",
          },
          {
            path: `/admin/orders`,
            title: "Orders",
            type: "link",
          },
          {
            path: `/admin/inquiries`,
            title: "Inquiries",
            type: "link",
          },
          {
            path: `/admin/quiz`,
            title: "Quiz",
            type: "link",
          },
        ],
      },
      {
        title: "Products",
        icon: "widget",
        type: "sub",
        active: false,
        children: [
          {
            path: `/admin/products`,
            title: "Products",
            type: "link",
          },
          {
            path: `/admin/products/ingredients`,
            title: "Ingredients",
            type: "link",
          },
          {
            path: `/admin/products/add-stock`,
            title: "Add-Stock",
            type: "link",
          },
          {
            path: `/admin/products/discount-codes`,
            title: "Discount-Codes",
            type: "link",
          },
        ],
      },
      {
        title: "Addons",
        icon: "project",
        type: "sub",
        active: false,
        children: [
          {
            path: `/admin/addons/content-pages`,
            title: "Content-Pages",
            type: "link",
          },
          {
            path: `/admin/addons/home-page`,
            title: "Home-Page",
            type: "link",
          },
          {
            path: `/admin/addons/about-us`,
            title: "About-Us",
            type: "link",
          },
          {
            path: `/admin/addons/partner`,
            title: "Partner",
            type: "link",
          },
          {
            path: `/admin/addons/blogs`,
            title: "Blogs",
            type: "link",
          },
          {
            path: `/admin/addons/faqs`,
            title: "FAQs",
            type: "link",
          },
          {
            path: `/admin/addons/testimonials`,
            title: "Testimonials",
            type: "link",
          },
          {
            path: `/admin/addons/subscriptions`,
            title: "Subscriptions",
            type: "link",
          },
          {
            path: `/admin/addons/headerimage`,
            title: "Header-Image",
            type: "link",
          },
          {
            path: `/admin/addons/whyriise`,
            title: "WhyRiise",
            type: "link",
          },
        ],
      },
      {
        title: "User Management",
        icon: "user",
        type: "sub",
        active: false,
        children: [
          {
            path: `/admin/user-management/users`,
            title: "Users",
            type: "link",
          },
          {
            path: `/admin/user-management/profile`,
            title: "User-Profile",
            type: "link",
          },
        ],
      },

      {
        title: "Website Settings",
        icon: "widget",
        type: "sub",
        active: false,
        children: [
          {
            path: `/admin/site-settings/basic`,
            title: "Basic",
            type: "link",
          },
          {
            path: `/admin/site-settings/contact`,
            title: "Contact",
            type: "link",
          },
          {
            path: `/admin/site-settings/seo`,
            title: "SEO",
            type: "link",
          },
          {
            path: `/admin/site-settings/embed-script`,
            title: "Embed-Script",
            type: "link",
          },
          {
            path: `/admin/site-settings/payment-gateways`,
            title: "Payment-Gateways",
            type: "link",
          },
        ],
      },
      {
        title: "Stripe",
        icon: "widget",
        type: "sub",
        active: false,
        children: [
          {
            path: `/admin/stripe/customers`,
            title: "Customers",
            type: "link",
          },
          {
            path: `/admin/stripe/subscriptions`,
            title: "Subscriptions",
            type: "link",
          },
          {
            path: `/admin/stripe/payments`,
            title: "Payments",
            type: "link",
          },
        ],
      },
    ],
  },
];
