import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Breadcrumbs, Btn, H5, H6} from "../../../AbstractElements";
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Label, Row, Spinner} from "reactstrap";
import DataTable from "react-data-table-component";
import {toast, ToastContainer} from "react-toastify";

import {useGetQuestionsaQuery, useUpdateQuestionMutation} from "../../../features/quizApiSlice";
import {useForm} from "react-hook-form";
import {useTranslateTextMutation} from "../../../features/translateApiSlice";
import Answer from "./Answer";

const Quiz = () => {

    const [updateQuestion] = useUpdateQuestionMutation();

    const [questions, setQuestions] = useState()
    const [answers, setAnswers] = useState()
    const [tableData, setTableData] = useState([])

    const [questionCode, setQuestionCode] = useState(0)
    const [selectedAnswers, setSelectedAnswers] = useState([])

    const [filteredAnswers, setFilteredAnswers] = useState([]);
    const [forceRender, setForceRender] = useState(false);


    const [translateText] = useTranslateTextMutation();

    const [selectedRow, setSelectedRow] = useState([]);
    const [activeChecked, setActiveChecked] = useState(false)
    const [passwordChecked, setPasswordChecked] = useState(false)
    const [toggleSelect, setToggleSelect] = useState(false);
    const [editing, setEditing] = useState(false)



    const [answersValues, setAnswersValues] = useState([])

    const [values1, setValues1] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values2, setValues2] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values3, setValues3] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values4, setValues4] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values5, setValues5] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values6, setValues6] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values7, setValues7] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values8, setValues8] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values9, setValues9] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values10, setValues10] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values11, setValues11] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values12, setValues12] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values13, setValues13] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values14, setValues14] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values15, setValues15] = useState({code:0,answer:'',answerDe:'',img:''});
    const [values16, setValues16] = useState({code:0,answer:'',answerDe:'',img:''});

    
    
    const { data: fetchQuestions,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetQuestionsaQuery({skip: false});

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
                console.log(fetchQuestions)
                setQuestions(fetchQuestions.questions)
                setAnswers(fetchQuestions.answers)

                const filterData = fetchQuestions.questions.filter((e) => {
                    if(e.language_code === 'en') return e;
                })

                setTableData(filterData)

                answersValues.push(values1)
                answersValues.push(values2)
                answersValues.push(values3)
                answersValues.push(values4)
                answersValues.push(values5)
                answersValues.push(values6)
                answersValues.push(values7)
                answersValues.push(values8)
                answersValues.push(values9)
                answersValues.push(values10)
                answersValues.push(values11)
                answersValues.push(values12)
                answersValues.push(values13)
                answersValues.push(values14)
                answersValues.push(values15)
                answersValues.push(values16)

            }
        }
        fn();

    }, [fetchQuestions]);


    useEffect(() => {
        //console.log(filteredAnswers)


        setSelectedAnswers(filteredAnswers)


    }, [filteredAnswers]);


    const {
        register,
        setValue,
        getValues,
        reset,
        control,
        setError,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            question: '',
            question_de: ''
        }
    });

    const handleRowSelected = useCallback(state => {

        setSelectedRow(state.selectedRows);
        //console.log(selectedRow)

        if(state.selectedCount === 0) {
            reset();
        }

    }, []);

    const onSaveClick = async () => {

        //console.log(selectedAnswers.length)

        const questionsData = [];
        questionsData.push({code: questionCode, question: getValues('question'), lang: 'en'})
        questionsData.push({code: questionCode, question: getValues('question_de'), lang: 'de'})

        const answersData = [];

        if(selectedAnswers.length > 0) {
            //selectedAnswers.forEach((e, i) => {
            for(let i = 0; i < selectedAnswers.length /2; i++) {
                //console.log(i)

                const values = answersValues[i];
                //console.log(values)
                if (values && values.code > 0) {
                    answersData.push({code: values.code, answer: values.answer, lang: 'en'})
                    answersData.push({code: values.code, answer: values.answerDe, lang: 'de'})

                }
            }
            //})
        }

        console.log(answersData)
        try {
            const response = await updateQuestion({question: questionsData, answers: answersData})

            console.log(response.data)
            if(response.data.message === 'Success.') {

                updateAfterSave();

                toast.success("Quiz updated.");
            }
        }
        catch(err) {
            console.log(err.message)
            toast.error("Quiz didn't update.");
        }

    }

    const updateAfterSave = () => {

        const updatedQuestions = questions.map(item => {
            if (item.code === questionCode && item.language_code === 'en') {
                return { ...item, question: getValues('question') };
            }
            else if (item.code === questionCode && item.language_code === 'de') {
                return { ...item, question: getValues('question_de') };
            }
            return item;
        });

        setQuestions(updatedQuestions);


        let markers = [...answers];

        //selectedAnswers.forEach((e, index) => {
        for(let i = 0; i < selectedAnswers.length /2; i++) {

            const values = answersValues[i];

            if (values.code > 0) {

                const updatedAnswers = markers.map(item => {
                    if (item.code === values.code && item.language_code === 'en') {
                        return {...item, answer: values.answer};
                    } else if (item.code === values.code && item.language_code === 'de') {
                        return {...item, answer: values.answerDe};
                    }
                    return item;
                });

                markers = updatedAnswers;
            }
        }
        //})

        setAnswers(markers);

    }

    const handleShow = async () => {

        //setSelectedAnswers([])

        if(selectedRow.length > 0) {
            //console.log(selectedRow)
            const filteredQ = questions.filter((e) => {
                if(e.code === selectedRow[0].code)
                    return e;
            })

            for (const q of filteredQ) {

                if(q.language_code === 'en') {
                    setQuestionCode(q.code)
                    setValue('question', q.question)
                }
                else
                    setValue('question_de', q.question)
            }

            //filteredAnswers.splice(0, filteredAnswers.length);


            const a = answers.filter((e) => {
                if(e.question_code === selectedRow[0].code && e.type !== 'text' && e.type !== 'select') {
                    //filteredAnswers.push(e)
                    return e;
                }
            })

            answersValues.forEach((e) => e.code = 0);
            // filteredAnswers.splice(0, selectedAnswers.length)
            // selectedAnswers.splice(0, selectedAnswers.length)

            //setFilteredAnswers([]);
            setSelectedAnswers([]);


            setFilteredAnswers(a);

            // Toggle forceRender to force a re-render
            setForceRender(prev => !prev);

        }
    }

    const onTranslate = async () => {

        const contents = getValues('question').trim();

        if(contents !== '') {
            try {
                setValue('question_de', '');

                const response = await translateText({contents}).unwrap();

                setValue('question_de', response.data);
            }
            catch(err) {
                console.log(err);
                //toast.error(err);
            }

        }
    }


    const tableColumns = [
        {
            name: 'Id',
            selector: row => `${row.id}`,
            omit: true
        },
        {
            name: 'Code',
            selector: row => row['code'],
            sortable: true,
            center: false,
            width: "80px",
            headerStyle: (selector, id) => {
                return { textAlign: "center" };
            },
        },
        {
            name: 'Question',
            selector: row => row['question'],
            sortable: true,
            center: false,
        },
    ];

    return(
        <Fragment>
            {isLoading && <Spinner />}
            <Breadcrumbs mainTitle='Web' parent="Web" title="Quiz" subParent='' />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <H5>Quiz Management</H5>
                            </CardHeader>
                            <CardBody>
                                <H6>Question Code: {questionCode}</H6>
                                <Row>
                                    <Col md='6 mb-3'>
                                        <Label className='form-label' for='question'>Question (English)</Label>
                                        <input className='form-control' id='question' type='text' placeholder='Question' name='question' {...register('question', { required: true })} />
                                        <span className='d-block'>{errors.question && 'Question is required'}</span>

                                    </Col>
                                    <Col md='6 mb-3'>
                                        <div>
                                            <Label className='form-label' for='question_de'>Question (German)</Label> &nbsp;
                                            <Button type='button' className='btn py-0 px-2' onClick={onTranslate}><i className="fa fa-language"></i></Button>
                                        </div>
                                        <input className='form-control' id='question_de' type='text' placeholder='Question' name='question_de' {...register('question_de')} />

                                    </Col>
                                </Row>
                                <H5>Answers</H5>
                                <Row md='mb-3'>
                                    <Col md='4'>
                                        <Label className='form-label'>English</Label>
                                    </Col>
                                    <Col md='1'>
                                        <Label className='form-label'>Translate</Label>
                                    </Col>
                                    <Col md='4'>

                                        <Label className='form-label'>German</Label>

                                    </Col>
                                    <Col md='1'>
                                        <Label className='form-label'>Image</Label>
                                    </Col>
                                    <Col md='2'>
                                        <Label className='form-label'>Upload Image</Label>
                                    </Col>
                                </Row>
                                {selectedAnswers && selectedAnswers[0] && <Answer data={selectedAnswers[0]} data_de={selectedAnswers[1]} values={values1}/> }
                                {selectedAnswers && selectedAnswers[2] && <Answer data={selectedAnswers[2]} data_de={selectedAnswers[3]} values={values2}/> }
                                {selectedAnswers && selectedAnswers[4] && <Answer data={selectedAnswers[4]} data_de={selectedAnswers[5]} values={values3}/> }
                                {selectedAnswers && selectedAnswers[6] && <Answer data={selectedAnswers[6]} data_de={selectedAnswers[7]} values={values4}/> }
                                {selectedAnswers && selectedAnswers[8] && <Answer data={selectedAnswers[8]} data_de={selectedAnswers[9]} values={values5}/> }

                                {selectedAnswers && selectedAnswers[10] && <Answer data={selectedAnswers[10]} data_de={selectedAnswers[11]} values={values6}/> }
                                {selectedAnswers && selectedAnswers[12] && <Answer data={selectedAnswers[12]} data_de={selectedAnswers[13]} values={values7}/> }
                                {selectedAnswers && selectedAnswers[14] && <Answer data={selectedAnswers[14]} data_de={selectedAnswers[15]} values={values8}/> }
                                {selectedAnswers && selectedAnswers[16] && <Answer data={selectedAnswers[16]} data_de={selectedAnswers[17]} values={values9}/> }
                                {selectedAnswers && selectedAnswers[18] && <Answer data={selectedAnswers[18]} data_de={selectedAnswers[19]} values={values10}/> }
                                {selectedAnswers && selectedAnswers[20] && <Answer data={selectedAnswers[20]} data_de={selectedAnswers[21]} values={values11}/> }

                                {selectedAnswers && selectedAnswers[22] && <Answer data={selectedAnswers[22]} data_de={selectedAnswers[23]} values={values12}/> }
                                {selectedAnswers && selectedAnswers[24] && <Answer data={selectedAnswers[24]} data_de={selectedAnswers[25]} values={values13}/> }
                                {selectedAnswers && selectedAnswers[26] && <Answer data={selectedAnswers[26]} data_de={selectedAnswers[27]} values={values14}/> }
                                {selectedAnswers && selectedAnswers[28] && <Answer data={selectedAnswers[28]} data_de={selectedAnswers[29]} values={values15}/> }
                                {selectedAnswers && selectedAnswers[30] && <Answer data={selectedAnswers[30]} data_de={selectedAnswers[31]} values={values16}/> }

                            </CardBody>
                            <CardFooter>
                                <Btn attrBtn={{ color: 'primary', onClick: () => onSaveClick() }}>Save</Btn>
                            </CardFooter>

                            <CardBody>
                                <>
                                    {(selectedRow.length !== 0) && <>
                                        <div className={'float-end w-100 d-flex bg-light-info'}>
                                            <div className={`d-flex align-items-center justify-content-between p-2`}>
                                                <Btn attrBtn={{ color: 'primary', onClick: () => handleShow() }}>Show Details</Btn>

                                            </div>

                                        </div>
                                    </>
                                    }
                                    <DataTable
                                        columns={tableColumns}
                                        data={tableData}
                                        striped={true}
                                        center={true}
                                        pagination
                                        selectableRows
                                        selectableRowsSingle
                                        selectableRowsHighlight
                                        onSelectedRowsChange={handleRowSelected}
                                        clearSelectedRows={toggleSelect}
                                    />
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Fragment>
    );
};

export default Quiz;