import { apiSlice } from "../app/api/apiSlice";

export const quizApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getQuestionsa: builder.query({
            query: () => ({url: `quiz/questionsa`}),
            validateStatus: (response, result) => {
                console.log(result)
                return response.status === 200 && !result.isError
            },
        }),
        updateQuestion: builder.mutation({
            query: payload => ({
                url: `quiz`,
                method: 'POST',
                body: { ...payload }
            })
        }),
    })
})

export const {
    useGetQuestionsaQuery,
    useUpdateQuestionMutation,
} = quizApiSlice